.app-version-dialog {
  .MuiPaper-root {
    width: 100%;
    max-width: 560px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    .MuiDialogContent-root {
      padding: 30px 30px;
      box-sizing: border-box;
      width: 100% !important;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
    .text-para {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin: 8px auto 16px;
      max-width: 100%;
      text-align: center;
      max-width: 326px;
    }
  }

  button {
    color: #ffffff;
    font-family: "TTCommons-Regular";
    width: auto;
    min-width: 124px;
    padding: 5px 15px 1px;
    height: auto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    border-radius: 45px;
  }

  .version-content {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    margin: 1rem 0;
    strong {
      font-family: "TTCommons-DemiBold";
      font-weight: 700;
    }
  }
}
