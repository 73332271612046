.discover-topic-dialog {
  .MuiDialog-container {
    + div {
      display: none;
    }
  }
  .MuiDialogContent-root {
    padding: 0;
    .MuiDialogContentText-root {
      padding: 50px 60px !important;
      margin: 0;
    }
  }
  .title {
    color: var(--primary-color);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center;
    max-width: 354px;
    margin: 0 auto 20px;
    font-family: "TTCommons-Medium";
  }
  .sub-title {
    color: var(--primary-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    max-width: 336px;
    + svg {
      top: -5px;
      right: -5px;
    }
  }
  .modal-btn-wrap {
    margin: 50px 0 0 0;
    text-align: center;
    button {
      height: auto;
      padding: 20.85px 25px 14.85px;
      border-radius: 45px;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24.3px;
      text-align: center;
      font-family: "TTCommons-Medium";
      .content {
        text-transform: initial !important;
      }
    }
  }
}
