@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";
.o-career-information-profile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    gap: 12px;
  }
  .o-career-information__grid {
    gap: 20px;
    align-items: flex-end;
  }
}
.o-career-information {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: flex-end;
    @media (max-width: 767px) {
      gap: 12px !important;
    }
  }
}

@media (max-width: 78em) {
  .o-career-information-profile {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 48em) {
  .o-career-information-profile {
    grid-template-columns: 1fr;
    .o-career-information__grid {
      grid-template-columns: 1fr;
    }
  }
  o-career-information {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
@media (max-width: 27em) {
  .o-career-information {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
