@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";
.profile-education-container__collapse {
  .profile-education-container__top-heading {
    display: none;
  }
}
.profile-education-container {
  // margin-top: 30px;
  &__top-heading {
    margin: 0;
    color: var(--primary-dark-color);
    @include TTCommonsMedium;
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 15px;
    padding-top: 4px;
  }
}
