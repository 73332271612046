.profile-header {
  position: relative;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__title {
    h2 {
      margin: 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      padding-top: 4px;
      text-align: center;
    }
    &__button {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      &__save {
        margin: 0 0 0 0 !important;
      }
      @media (min-width: 635px) {
        position: absolute;
        top: -14px;
        right: 0;
        margin-top: 0px;
      }
      button {
        width: 108px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff !important;
        box-shadow: var(--card-box-shadow) !important;
        color: var(--primary-dark-color);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Medium";
        border-radius: 100px;
        transition: all 0.3s;
        span {
          height: 15px;
          margin: 0 8px 0 0;
        }
        &:focus {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
      }
      &__save {
        button {
          background: var(--link-color) !important;
          color: white !important;
        }
      }
    }
  }
  &__signout-modal {
    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      button {
        margin: 30px 0 30px 0;
        background: var(--secondary-color);
        color: #ffffff;
        width: 200px;
        height: 50px;
        border-radius: 25px;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        &:hover {
          opacity: 0.9;
        }
      }
      &__cancel {
        font-size: 18px;
        line-height: 16px;
        color: var(--gray-text-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--gray-text-color);
        cursor: pointer;
        &:focus {
          padding: 0 2px;
          outline: 2px solid var(--primary-dark-color) !important;
        }
      }
    }
  }
}
