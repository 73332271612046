.webinar-dialog {
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 480px;
  }
  .MuiDialogContent-root {
    padding: 30px 30px;
    width: 100% !important;
    .MuiTypography-root {
      padding: 0 !important;
      margin: 0;
    }
    #alert-dialog-description {
      padding: 0 !important;
      margin: 0;
    }
  }

  &.web-detail-opening .MuiDialog-paper {
    margin: 0;
    overflow: hidden;
  }

  .makeStyles-dialogHeader-3 {
    svg {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
    }
    .makeStyles-dialogTitle-4 {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
      margin: 0 0 30px;
    }
    .makeStyles-dialogSubTitle-5 {
      margin: 0;
      color: var(--primary-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
  }

  .webinar-dialog-content {
    margin: 0;
    text-align: center;
    padding: 0;
    .img-left {
      width: 100%;
      max-width: 252px;
      min-width: 252px;
      border-radius: 10px;
      background-color: var(--icon_background);
      overflow: hidden;
      position: relative;
      min-height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 16px;
      @media (max-width: 767px) {
        max-width: 220px;
        min-width: 220px;
      }
      img {
        width: 100%;
        max-height: 128px;
        vertical-align: middle;
      }
      .default-image {
        width: auto;
      }
      .webinarCategory {
        border: 2px solid var(--icon_background);
        border-radius: 100px;
        background-color: #ffffff;
        padding: 6px 16px 1px;
        width: auto;
        display: flex;
        align-items: center;
        min-height: 29px;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        position: absolute;
        top: 12px;
        left: 12px;
        @media (max-width: 767px) {
          top: 8px;
          left: 8px;
        }
      }
    }
    .header-primary {
      margin-bottom: 8px;
    }
    .decsription {
      font-size: 15px;
      -webkit-letter-spacing: 0;
      -moz-letter-spacing: 0;
      -ms-letter-spacing: 0;
      letter-spacing: 0;
      line-height: 17px;
      color: var(--gray-text-color);
      margin-bottom: 8px;
    }
    h3 {
      margin: 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
    h5 {
      display: flex;
      align-items: center;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      color: var(--primary-dark-color);
      margin: 0px auto 10px;
      font-weight: 400;
      justify-content: center;
      img {
        margin-right: 8px;
        margin-top: -4px;
      }
    }
    .btn {
      margin-top: 15px;
      button {
        width: auto;
        min-width: 140px;
      }
      .up-btn {
        border-radius: 45px;
        background-color: #8094ab;
        color: #ffffff;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
}
