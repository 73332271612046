.friend-model {
  .MuiDialogContent-root {
    width: 100% !important;
    max-width: 710px;
    padding: 50px;
    .MuiDialogContentText-root {
      padding: 0 !important;
      margin: 0 !important;
    }
    .makeStyles-btnClose-7 {
      top: -5px;
      right: -5px;
    }
  }
  .share-content {
    padding: 30px 50px 39px 40px;
    color: var(--gray-text-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: left;
    margin: 46px 0 36px 0;
    border: 2px solid var(--light-gray-color);
    border-radius: 60px 60px 60px 0;
    background-color: #ffffff;
    max-width: 555px;
    width: 100%;
    .text-secondary {
      border-bottom: 1px solid var(--secondary-color);
      text-decoration: none;
    }
  }
  .social-links {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      li {
        width: 60px;
        height: 60px;
        cursor: pointer;
        img {
          width: 100%;
        }

        + li {
          margin-left: 20px;
        }
      }
    }
    button {
      min-width: 220px;
      margin: 0px 0 0 auto;
      text-align: center !important;
      padding: 19.85px 20.54px 15.85px 21.46px !important;
      height: auto;
      border-radius: 45px;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24.3px;
    }
  }
  .btn {
    text-align: center;
    button {
      background: transparent;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      color: var(--gray-text-color);
      padding: 0;
      width: auto;
      height: auto;
      border-bottom: 1px solid var(--gray-text-color);
      border-radius: 0;
      min-width: auto;
      .content {
        min-width: auto;
      }
    }
  }
}
