@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";

.o-leadership-experience{
  &__grid{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 30px;
    &__item{
      max-width: 265px;
      width: 265px;
      height: 102px;
      display: flex;
      flex-direction: column;
      &__row{
        width: 100%;
        display: flex;
        justify-content: space-between;
        &__left{
          min-width: 127px;
        }
        &__right{
          min-width: 127px;
        }
      }
      label {
        color: var(--field-label-color);
        font-size: 15px;
        padding: 5px 0 0 0;
        margin: 0 0 6px 8px;
        line-height: 14px;
        display: block;
        font-family: "TTCommons-Regular";
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
      }
    }
    &__height0 {
      height: 0;
    }
  }
  &__check{
    display: flex;
    align-items: center;
    margin-top: 12px;
    a {
      border: 2px solid #D1DDE5;
      &:focus {
        border: 2px solid var(--primary-color);
      }
    }
    &__text{
      display: block;
      height: 15px;
      margin-left: 8px;
      letter-spacing: 0;
      font-size: 15px;
      line-height: 16px;
      font-family: "TTCommons-Regular";
      color: var(--gray-text-color);
      font-weight: 400;
    }
    &__filled{
      margin-top: 38px;
    }
    &__remote {
      margin-top: -30px;
    }
  }
}

