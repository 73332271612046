@import "../../../../styles/colors.scss";
.messages-container {
  position: relative;
  height: 100%;
  -ms-overflow-style: auto;
  overscroll-behavior: none;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $color-grey transparent; /* scroll thumb and track */
  transition: scrollbar-color 0.3s ease-out;
  padding-right: 10px;
  overflow-y: scroll !important;
  overflow-x: hidden;
  &__emoji-picker {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 5;
  }
  &.messages-container__fresh {
    height: auto !important;
  }
  .message-preview__item {
    height: 70px;
  }
  .show-older-messages {
    display: flex;
    height: 0px !important;
    margin: 11px !important;
    > span {
      cursor: pointer;
    }
    .date {
      width: 140px !important;
      color: $color-orange !important;
    }
  }
  .divider {
    height: 1px;
    background-color: #bfced8;
    position: relative;
    margin: 20px 0;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-grey-2;
      background-color: $color-white;
      font-size: 15px;
      text-align: center;
      line-height: 16px;
      min-width: 100px;
      padding: 0 4px;
      margin-top: 2px;
    }
  }

  &__request-meeting {
    &__time {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 5px;
      margin: 0 10px 5px 10px;
      span {
        display: block;
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #607790;
        height: 12px;
      }
      &__own {
        justify-content: flex-end !important;
      }
    }
    &__banner {
      min-height: 60px;
      border-radius: 10px;
      margin: 0 0 29px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px;
      gap: 20px;
      background-color: #f1f4f7;
      span:first-child {
        color: #000c3d;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
      }
      span:last-child {
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        cursor: pointer;
      }
    }
  }
  &__meeting-dialog {
    margin-top: 35px;
    &__title {
      margin: -40px 0 33px 0;
      h2 {
        margin: 0 0 0 0;
        color: #000c3d;
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        text-align: center;
      }
    }
    &__avatar {
      display: flex;
      justify-content: center;
    }
    &__name {
      margin-top: 22px;
      h3 {
        margin: 0 0 0 0;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 28px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
      }
    }
    &__message {
      margin-top: 33px;
      textarea {
        width: 100%;
        height: 234px;
        padding: 20px;
        border: 1px solid transparent;
        border-radius: 10px;
        background-color: #f1f4f7;
        resize: none;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        // Scroll styling
        overflow-y: auto;
        scrollbar-width: thin;
        -ms-overflow-style: auto;
        overscroll-behavior: none;
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: lightgray transparent; /* scroll thumb and track */
        transition: scrollbar-color 0.3s ease-out;

        &::-webkit-scrollbar {
          width: 5px; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
        }

        &::-webkit-scrollbar-track-piece:end {
          margin-bottom: 5px;
        }

        &::-webkit-scrollbar-track-piece:start {
          margin-top: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: lightgray !important; /* color of the scroll thumb */
          border-radius: 20px; /* roundness of the scroll thumb */
          border: none;
          height: 10px !important;
        }

        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }

        &::-webkit-scrollbar-thumb:hover {
          border: 0px;
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--secondary-font-color);
          opacity: 1 !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--secondary-font-color);
          opacity: 1 !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--secondary-font-color);
          opacity: 1 !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--secondary-font-color);
          opacity: 1 !important;
        }
        &:focus {
          box-shadow: 0 0 8px 0 var(--primary-font-color) !important;
          border-color: var(--primary-font-color) !important;
        }
        &:-webkit-autofill {
          border-radius: 45px;
        }
      }
    }
    &__button {
      display: flex;
      justify-content: center;
      margin-top: 35px;
    }
  }
}

.messages-container::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.messages-container::-webkit-scrollbar-track {
  background: #ffffff !important; /* color of the tracking area */
  border: 2px solid $color-grey-4;
  border-radius: 50px;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: $color-grey-4 !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 10px !important;
}

.messages-container::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  border: 0;
}
