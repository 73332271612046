// 06-01-2021
@media (max-width: 1500px) {
  body {
    .upload-signup-profile-pic {
      margin-bottom: 60px;
      + button {
        position: initial;
        bottom: initial;
        left: initial;
        transform: initial;
      }
    }
  }
  .new-msg-dialog {
    .MuiDialog-paperScrollPaper {
      height: 100%;
    }
    .dialog-content {
      > div {
        &:first-child {
          > div {
            &:nth-child(2) {
              margin-top: 16px;
              > div {
                > div {
                  margin-bottom: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1367px) {
  .ShowResponsiveDiv {
    display: none;
  }
}

@media (max-width: 1366px) {
  body {
    .MuiButtonBase-root.MuiButton-contained {
      overflow: hidden;
    }
    .onboarding-layout {
      .MuiGrid-container {
        .onboarding-left-sidebar {
          flex-grow: 0;
          max-width: 35%;
          flex-basis: 35%;
          background-position: left;
          .left-sidebar-content {
            ol {
              li {
                padding: 0px 20px;
                h1 {
                  font-size: 22px !important;
                  line-height: 24px !important;
                  margin-left: 15px !important;
                }
                + li {
                  margin-top: 30px !important;
                }
                &:first-child {
                  margin-top: 30px;
                }
                &:last-child {
                  margin-bottom: 30px;
                }
              }
            }
          }
        }
        .right-sidebar {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .custom-header-container,
    .header-wrapper {
      .header {
        .navbar {
          width: auto;
          ul.menu {
            position: initial;
            transform: none;
            margin-right: 30px;
          }
        }
      }
    }
    .footer {
      .footer-content {
        padding: 30px 60px 30px !important;
        position: relative;
        .btn-close {
          position: absolute;
          right: 35px;
          top: 55px;
        }
        ul {
          flex-wrap: wrap;
          padding: 0 0px !important;
          li {
            &:first-child {
              width: 100%;
              margin: 0 0 20px 0;
              .logo-content {
                display: block;
                text-align: center;
                width: 100%;
              }
              .tag-line {
                width: 100%;
                text-align: center;
              }
              .footer-title {
                margin: 0 0 15px 0;
              }
              a {
                margin: 0 0 10px;
              }
            }
          }
        }
        .copy-right {
          text-align: center;
          margin: 15px 0 0 0;
        }
      }
    }
    .custom-container {
      max-width: 1030px !important;
      padding: 0 15px 30px !important;
    }
    .dashboard-content {
      .MuiGrid-container {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 35%;
            flex-basis: 35%;
          }
          &:last-child {
            max-width: 65%;
            flex-basis: 65%;
            margin-left: auto;
            display: none;
            .slick-slider {
              .slick-list {
                max-width: 320px;
                margin: 0 auto;
              }
            }
            .webinars-wrapper {
              .empty-item {
                max-width: 300px;
                margin: 0 auto;
              }
            }
          }
        }
        .MuiGrid-grid-xs-6 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .savedposts-content {
      .infinite-scroll-component {
        > div {
          margin: 0px -8px;
          @media (max-width: 767px) {
            margin: 0px 0px;
          }
          > div {
            padding: 0 8px;
            .dashboard-card {
              padding: 20px 30px;
            }
          }
        }
      }
    }
    .boost-profile-content {
      .MuiGrid-grid-xs-3 {
        &:first-child {
          max-width: 32%;
          flex-basis: 32%;
        }
        &:last-child {
          max-width: 68%;
          flex-basis: 68%;
          margin-left: auto;
          margin-top: 16px;
          .card-content {
            max-width: 450px;
            margin: 0 auto;
          }
        }
      }
      .MuiGrid-grid-xs-6 {
        max-width: 68%;
        flex-basis: 68%;
      }
    }
    .profileContent {
      .custom-container {
        display: flex;
        flex-wrap: wrap;
        .btn-test {
          justify-content: center;
          margin: 0 auto 24px !important;
          width: 100%;
          order: 2;
          button {
            margin: 0 auto;
          }
        }
        > div {
          &:nth-child(2) {
            order: 1;
            width: 100%;
            margin: -32px 0 0 0;
            .profile-link {
              margin-bottom: 24px;
            }
          }
        }
        form {
          order: 3;
          width: 100%;
          .profile-containers {
            .MuiGrid-grid-xs-3 {
              max-width: 50%;
              flex-basis: 50%;
              &:nth-child(3),
              &:nth-child(4) {
                margin-top: 50px;
              }
              .title-primary {
                text-align: center;
              }
              .card-content {
                max-width: 300px;
                margin: 0 auto;
                padding: 25px 15px 0;
              }
            }
          }
        }
      }
    }
    .network-content {
      .MuiGrid-root {
        .MuiGrid-grid-xs-6 {
          max-width: 60%;
          flex-basis: 60%;
        }
        .MuiGrid-grid-xs-4 {
          max-width: 40%;
          flex-basis: 40%;
        }
      }
    }
    .discover-network-wrapper {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 35%;
            flex-basis: 35%;
            .search-card {
              .card-content {
                max-width: 320px !important;
                padding: 20px;
                margin: 0 auto;
              }
            }
          }
          &:last-child {
            max-width: 65%;
            flex-basis: 65%;
            margin-left: auto;
            display: none;
          }
          .recom-details-card {
            .card-content {
              max-width: 320px;
              margin: 0 auto;
            }
          }
        }
        .MuiGrid-grid-xs-9,
        .MuiGrid-grid-xs-6 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .mytopic-mainpage {
      .MuiGrid-grid-xs-3 {
        &:first-child {
          max-width: 35%;
          flex-basis: 35%;
        }
        &:nth-child(3) {
          max-width: 65%;
          flex-basis: 65%;
          margin-left: auto;
          .right-section {
            .details {
              .card-content {
                max-width: 320px;
                margin: 0 auto;
              }
            }
          }
        }
      }
      .MuiGrid-grid-xs-6 {
        max-width: 65%;
        flex-basis: 65%;
      }
    }
    .discover-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 35%;
            flex-basis: 35%;
            .search-section {
              .search-card {
                padding: 30px;
                .card-content {
                  max-width: 320px;
                  margin: 0 auto;
                }
              }
            }
          }
          &:last-child {
            max-width: 65%;
            flex-basis: 65%;
            margin-left: auto;
            .right-section {
              .details {
                .card-content {
                  max-width: 320px;
                  margin: 0 auto;
                  position: relative;
                  .card-close-btn {
                    top: -5px;
                    right: -5px;
                  }
                }
              }
            }
          }
        }
        .MuiGrid-grid-xs-9,
        .MuiGrid-grid-xs-6 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .job-dashboard {
      .job-content {
        .MuiGrid-container {
          > .MuiGrid-grid-xs-3 {
            max-width: 35%;
            flex-basis: 35%;
            .find-job-section {
              .search-card {
                padding: 20px 20px;
                .card-content {
                  max-width: 320px !important;
                  margin: 0 auto;
                }
              }
            }
            &:last-child {
              display: none;
            }
          }
          > .MuiGrid-grid-xs-6 {
            max-width: 65%;
            flex-basis: 65%;
            .recommanded-list {
              .MuiGrid-grid-xs-6 {
                max-width: 50%;
                flex-basis: 50%;
              }
            }
          }
        }
      }
    }
    .saved-searches {
      .search-list {
        .MuiGrid-spacing-xs-3 {
          margin: 0;
          width: 100%;
          .MuiGrid-grid-xs-9 {
            max-width: 65%;
            flex-basis: 65%;
            padding: 0;
          }
          .MuiGrid-grid-xs-3 {
            max-width: 35%;
            flex-basis: 35%;
            .edit-saved-search {
              .MuiCardContent-root {
                > div {
                  max-width: 320px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
    .saved-jobs {
      .MuiGrid-spacing-xs-3 {
        margin: 0;
        width: 100%;
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          padding: 0;
        }
      }
    }
    .search-mainpage {
      .job-section {
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
          .search-filter {
            .search-card {
              padding: 20px 20px;
              .card-content {
                max-width: 320px !important;
                margin: 0 auto;
              }
            }
          }
          .save-list {
            display: none;
          }
          &:last-child {
            display: none;
          }
        }
        .MuiGrid-grid-xs-6 {
          max-width: 65%;
          flex-basis: 65%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .alert-mainpage {
      .job-alert {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            &:first-child {
              max-width: 35%;
              flex-basis: 35%;
            }
            &:last-child {
              display: none;
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 65%;
            flex-basis: 65%;
          }
        }
      }
    }
    .visa-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 35%;
            flex-basis: 35%;
            .search-filter {
              .MuiCardContent-root {
                padding: 20px 20px;
                > div {
                  max-width: 320px;
                  margin-left: auto;
                  margin-right: auto;
                }
              }
            }
          }
          &:last-child {
            display: none;
          }
        }
        .MuiGrid-grid-xs-6,
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .company-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
          .company {
            .top-section {
              max-width: 100%;
              .company-title {
                display: flex;
                align-items: center;
                .ViewDetails {
                  display: block;
                  margin-left: 15px;
                  button {
                    background-color: var(--gray-text-color);
                    height: 30px;
                  }
                }
              }
            }
            .body-section {
              .company-card {
                .card-left {
                  padding-right: 15px;
                  width: 100%;
                  border: 0;
                  padding: 0;
                }
                .card-right {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .started-mainpage {
      .resource-section {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            &:first-child {
              max-width: 35%;
              flex-basis: 35%;
            }
            &:last-child {
              display: none;
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 65%;
            flex-basis: 65%;
          }
        }
      }
    }
    .learn-dashboard {
      .learn-content {
        .MuiGrid-grid-xs-4 {
          &:first-child {
            max-width: 35%;
            flex-basis: 35%;
            .webinar-list {
              margin-bottom: 0;
            }
          }
          &:nth-child(2) {
            max-width: 65%;
            flex-basis: 65%;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
    .webinar-section {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 35%;
            flex-basis: 35%;
            .search-filter {
              .search-card {
                padding: 30px 30px;
                .MuiCardContent-root {
                  max-width: 320px;
                  margin: 0 auto;
                }
              }
            }
          }
          &:last-child {
            display: none;
          }
        }
        .MuiGrid-grid-xs-6,
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .ShowResponsiveDiv {
      margin: 15px 0 0 0;
      .create-alert {
        margin: 15px 0 0 0;
      }
    }
    .create-alert {
      padding: 30px 15px !important;
      .close-btn {
        right: 15px !important;
      }
    }
    .resource-mainpage {
      .resource-section {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            max-width: 35%;
            flex-basis: 35%;
            &:last-child {
              display: none;
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 65%;
            flex-basis: 65%;
          }
        }
      }
    }
    .student-mainpage {
      .student-section {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            max-width: 35%;
            flex-basis: 35%;
            &:last-child {
              display: none;
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 65%;
            flex-basis: 65%;
          }
        }
      }
    }
    .country-mainpage {
      .country-section {
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
          .country-right {
            .MuiGrid-root {
              > div {
                max-width: 100%;
                flex-basis: 100%;
                padding-top: 0;
                padding-bottom: 0;
                + div {
                  margin: 30px 0 0 0;
                }
              }
            }
          }
        }
      }
    }
    .tools-dashboard {
      .MuiGrid-root {
        .MuiGrid-grid-xs-7 {
          max-width: 65%;
          flex-basis: 65%;
        }
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
        }
      }
    }
    .CareerGameplanDashboard {
      .MuiGrid-root {
        .MuiGrid-grid-xs-1 {
          max-width: 0%;
          flex-basis: 0%;
        }
        .MuiGrid-grid-xs-3 {
          max-width: 30%;
          flex-basis: 30%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 68%;
          flex-basis: 68%;
        }
      }
    }
    .tools-checklist {
      .MuiGrid-container {
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .tools-calendar {
      .MuiGrid-container {
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
          .MuiGrid-container {
            .MuiGrid-grid-xs-8 {
              max-width: 100%;
              flex-basis: 100%;
            }
            .MuiGrid-grid-xs-4 {
              max-width: 100%;
              flex-basis: 100%;
              .event-details-card {
                .card-content {
                  max-width: 320px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
    .NotificationWrap {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
        }
      }
    }
    .notifaction-managesetting {
      .MuiGrid-container {
        .MuiGrid-grid-xs-3 {
          max-width: 35%;
          flex-basis: 35%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 65%;
          flex-basis: 65%;
          .panel-card {
            .card-content {
              padding: 15px 15px 15px 15px;
              .panel-details {
                width: 60%;
              }
              .panel-actions {
                .panel-action-label {
                  margin-left: auto;
                }
              }
            }
          }
          .info-card {
            .card-content {
              padding: 15px 15px 15px 15px;
            }
          }
        }
      }
    }
    .calendar-card {
      .card-content {
        padding: 15px 30px !important;
        .fc-view-harness {
          padding-bottom: 0 !important;
          height: auto !important;
        }
        .fc-view,
        .fc-scroller-liquid-absolute {
          position: initial;
        }
        .fc-header-toolbar {
          margin: 0 0 15px 0 !important;
        }
        .view-header {
          .cal-day-header {
            .fc-scrollgrid-sync-inner {
              height: auto !important;
              padding: 10px 0 8px;
              .fc-col-header-cell-cushion {
                font-size: 16px !important;
              }
            }
          }
        }
        .cal-event {
          > div {
            margin: 4px 4px;
            padding: 8px 8px 6px;
            font-size: 12px;
          }
        }
      }
    }
    .company-details-popup {
      .MuiPaper-root {
        .MuiDialogContent-root {
          // overflow-x: hidden;
        }
      }
    }
    #root {
      .country-mainpage {
        .country_listing {
          ul {
            li {
              a {
                padding: 17px 20px 13px;
                font-size: 18px;
              }
            }
          }
        }
        .cities_wrapper {
          ul {
            li {
              a {
                padding: 15px 15px 10px;
                font-size: 18px;
              }
            }
          }
        }
        .selected-contry-content {
          > .MuiGrid-root {
            > .MuiGrid-grid-md-2 {
              max-width: 25%;
              flex-basis: 25%;
            }
            > .MuiGrid-grid-md-10 {
              max-width: 75%;
              flex-basis: 75%;
            }
          }
        }
        .country-services-wrapper {
          ul {
            li {
              width: 33.33%;
              .wraper {
                padding: 15px 15px;
              }
            }
          }
        }
      }
    }
  }
  .webinar-section-title {
    h2 {
      font-size: 24px;
    }
  }
}

@media (max-width: 1200px) {
  .message-page {
    .MuiGrid-root {
      .message-filter {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        .title-primary {
          text-align: center;
        }
      }
      .MuiGrid-grid-xs-9 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        margin: 16px 0 0 0;
      }
    }
  }
  .msg-leftsidebar {
    .select-search {
      margin: 0 0 30px 0;
      .btn-plus {
        ul.submenu {
          left: auto;
          right: 0;
        }
      }
    }
  }
  body {
    .learn-dashboard {
      .learn-content {
        .MuiGrid-grid-xs-4 {
          &:first-child {
            max-width: 100%;
            flex-basis: 100%;
            .webinar-list {
              .empty-mw-card {
                .card-content {
                  max-width: 400px;
                  margin: 0 auto;
                }
              }
            }
            .ShowResponsiveDiv {
              display: none;
            }
          }
          &:nth-child(2) {
            max-width: 100%;
            flex-basis: 100%;
            margin: 20px 0 0 0;
          }
          &:nth-child(3) {
            max-width: 100%;
            flex-basis: 100%;
            margin: 20px 0 0 0;
          }
          .title {
            .title-primary {
              text-align: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  body {
    .onboarding-layout {
      .MuiGrid-container {
        .onboarding-left-sidebar {
          max-width: 100%;
          flex-basis: 100%;
        }
        .right-sidebar {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
    .custom-header-container,
    .header-wrapper {
      .header {
        position: relative;
        flex-wrap: wrap;
        .logos {
          min-height: 44px;
        }
        .navbar {
          width: 100%;
          justify-content: center;
          ul.menu {
            margin-right: 0px;
            margin-left: 0px;
            li {
              padding: 12px 0 0px;
              a {
                font-size: 18px;
                line-height: 18px;
              }
            }
          }
          ul.header-rightsidebar {
            position: absolute;
            top: 10px;
            right: 30px;
          }
        }
      }
      .sub-menu {
        li {
          a {
            font-size: 16px;
            line-height: 18px;
            img {
              min-width: 28px;
              width: 28px;
              height: 28px;
            }
            span {
              font-size: 16px;
              line-height: 18px;
            }
          }
          + li {
            margin-left: 30px;
          }
        }
      }
    }
    .footer {
      .footer-content {
        ul {
          justify-content: end !important;
          li {
            width: 33.33%;
          }
        }
      }
    }
    .dashboard-content {
      .MuiGrid-container {
        margin: 0;
        width: 100%;
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 100%;
            flex-basis: 100%;
            padding: 0;
            order: 1;
          }
          &:last-child {
            max-width: 100%;
            flex-basis: 100%;
            padding: 0;
            order: 2;
            margin: 0 0 30px 0;
            .webinars-wrapper {
              display: none;
            }
          }
        }
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          padding: 0;
          order: 3;
          margin: 15px 0 0 0;
        }
      }
      .dashboard-leftsidebar {
        .boost-profile-card {
          .card-content {
            display: flex;
            > div {
              width: 50%;
              margin: 0;
              + div {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
    .layout-header-bar {
      padding: 0 15px !important;
      .relative-div {
        .breadcrumb-div {
          position: initial;
          transform: none;
          margin: 35px 0 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .relative-div {
      .breadcrumb-div {
        position: initial;
        transform: none;
        margin: 35px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .h1-maintitle {
      margin: 24px 0 16px;
      font-size: 32px;
      line-height: 32px;
    }
    .savedposts-content {
      .infinite-scroll-component {
        > div {
          > div {
            &:first-child {
              max-width: 100%;
              flex-basis: 100%;
            }
            &:last-child {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
      .saved-title {
        margin: 0 0 15px 0;
        width: 100%;
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          .post-sub-header {
            font-size: 24px !important;
            line-height: 18px !important;
          }
        }
      }
    }
    .boost-profile-content {
      .content-title {
        text-align: center;
      }
      .profile-status-card {
        .card-content {
          ul {
            li {
              a {
                justify-content: center;
                span {
                  min-width: 180px;
                  text-align: center;
                }
                .MuiAvatar-root {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
      .MuiGrid-grid-xs-3 {
        &:first-child {
          max-width: 100%;
          flex-basis: 100%;
        }
        &:last-child {
          max-width: 100%;
          flex-basis: 100%;
          margin-left: 0;
          .card-content {
            max-width: 450px;
            margin: 0 auto;
          }
        }
      }
      .MuiGrid-grid-xs-6 {
        max-width: 100%;
        flex-basis: 100%;
        margin-top: 16px;
      }
    }
    .network-content {
      .MuiGrid-root {
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          + div {
            margin: 16px 0 0 0;
          }
        }
        .MuiGrid-grid-xs-4 {
          max-width: 100%;
          flex-basis: 100%;
          + div {
            margin: 16px 0 0 0;
          }
        }
        .title-primary {
          text-align: center;
        }
        .title-boxs {
          justify-content: center;
          margin-bottom: 16px;
        }
      }
      .recommand-page {
        margin: 0px 0 0 0 !important;
      }
      .your-topic-page {
        margin: 0px 0 0 0 !important;
        .list {
          .empty-topic-card {
            .suggestion {
              max-width: 325px;
              margin: 0 auto;
              padding: 15px;
            }
          }
        }
      }
    }
    .discover-network-wrapper {
      .MuiGrid-root {
        margin: 0;
        width: 100%;
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 100%;
            flex-basis: 100%;
            padding: 0;
            .title-primary {
              text-align: center;
            }
          }
          &:last-child {
            max-width: 100%;
            flex-basis: 100%;
            margin-left: auto;
            padding: 0;
          }
        }
        .MuiGrid-grid-xs-9,
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 20px 0 0 0;
          padding: 0;
          .title-boxs {
            margin: 0 0 8px 0 !important;
            align-items: center;
          }
          .sort-by {
            margin-bottom: 0;
          }
        }
      }
      .search-card {
        .card-content {
          max-width: 360px;
          margin: 0 auto;
        }
      }
    }
    .mytopic-mainpage {
      .MuiGrid-spacing-xs-3 {
        margin: 0;
        width: 100%;
      }
      .MuiGrid-grid-xs-3 {
        &:first-child {
          max-width: 100%;
          flex-basis: 100%;
          padding: 0;
          .title-boxs {
            text-align: center;
          }
        }
        &:nth-child(3) {
          max-width: 100%;
          flex-basis: 100%;
          margin-left: 0;
          padding: 0;
        }
      }
      .MuiGrid-grid-xs-6 {
        max-width: 100%;
        flex-basis: 100%;
        padding: 0;
        margin: 8px 0 0 0;
        .middle-section {
          .title-withbutton {
            margin: 0 0 16px 0;
            justify-content: center;
          }
          .posts {
            margin: 0 0 16px 0;
            .MuiGrid-root {
              padding: 0px 0;
              + .MuiGrid-root {
                margin: 16px 0 0 0;
              }
            }
            .empty-middle-box {
              margin: 0;
            }
          }
        }
      }
      .my-topic-page {
        margin: 0 0 16px 0;
        .list {
          > div {
            > div {
              &:last-child {
                .info-panel-card {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .discover-mainpage {
      position: relative;
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 100%;
            flex-basis: 100%;
            .search-section {
              .title-primary {
                text-align: center;
              }
            }
            .search-section {
              .search-card {
                padding: 30px;
                .card-content {
                  max-width: 320px;
                  margin: 0 auto;
                }
              }
            }
          }
          &:last-child {
            max-width: 100%;
            flex-basis: 100%;
          }
        }
        .MuiGrid-grid-xs-9,
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 16px 0 0 0;
        }
      }
      .discover-page {
        .title-boxs.mb-17 {
          margin-bottom: 20px !important;
        }
      }
    }
    .message-page {
      .MuiGrid-grid-xs-9 {
        .msg-leftsidebar {
          padding: 30px 0;
          .chat-box-wrap {
            flex-wrap: wrap;
            .chat-div {
              width: 100%;
              padding: 0 0px;
              border-bottom: 0;
              border-right: 0 !important;
              padding-bottom: 20px;
              margin-bottom: 0;
              .chat-item {
                .chat-left {
                  h4 {
                    justify-content: flex-start;
                    small {
                      margin-left: 0;
                    }
                  }
                }
                .chat-right {
                  h4 {
                    justify-content: flex-end;
                  }
                }
                .msg-wrap {
                  max-width: 300px;
                  h4 {
                    flex-wrap: wrap;
                    small {
                      display: block;
                      width: 100%;
                    }
                  }
                }
              }
              .chat-window {
                .chat-div-wrap {
                  padding: 30px 20px 15px 15px;
                }
                .chat-type-box {
                  max-width: calc(100% - 30px);
                  margin: 0 auto;
                  left: 15px;
                }
              }
            }
            .ShowResponsiveDiv {
              display: block !important;
              .file-preview {
                display: block;
              }
            }
            .chat-profile-div {
              margin: 0 auto;
              border-top: 1.5px solid var(--light-gray-color) !important;
              padding-top: 30px;
              max-width: 100%;
              .chat-info {
                padding: 0;
                max-width: 304px;
                margin: 0 auto;
              }
            }
            .details {
              margin: 0 auto;
              padding: 0 20px 0 20px;
            }
          }
          .file-preview {
            display: none;
          }
        }
      }
    }
    .save-list {
      .job-title {
        justify-content: center;
      }
    }
    .job-dashboard {
      .job-content {
        .MuiGrid-container {
          > .MuiGrid-grid-xs-3 {
            max-width: 100%;
            flex-basis: 100%;
            margin: 0 0 8px 0;
            &:last-child {
              margin-bottom: 0;
            }
            .title-primary {
              text-align: center;
            }
            .ShowResponsiveDiv {
              display: none;
            }
          }
          > .MuiGrid-grid-xs-6 {
            max-width: 100%;
            flex-basis: 100%;
            .title-boxs {
              justify-content: center;
            }
            .recommanded-list {
              margin: 0 0 20px 0;
              .title-primary {
                text-align: center;
              }
            }
            .MuiGrid-grid-xs-3 {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
    }
    .search-list {
      .search-list-card {
        padding: 16px !important;
      }
    }
    .saved-jobs {
      .MuiGrid-spacing-xs-3 {
        margin: 0;
        width: 100%;
        > div {
          padding-left: 0;
          padding-right: 0;
        }
        .MuiGrid-grid-xs-9 {
          .saved-jobs-list {
            .card-wrapper {
              .MuiPaper-root {
                padding: 20px 15px 18px !important;
              }
            }
          }
        }
      }
    }
    .search-mainpage {
      .job-section {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
          .search-filter {
            .title-primary {
              text-align: center;
            }
          }
        }
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 0px 0 0 0;
          .search-list {
            margin-bottom: 20px;
          }
        }
      }
    }
    .search-mainpage.featured-jobs-list {
      .MuiGrid-grid-xs-9 {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    .alert-mainpage {
      .job-alert {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            &:first-child {
              max-width: 100%;
              flex-basis: 100%;
            }
            &:last-child {
              max-width: 100%;
              flex-basis: 100%;
              margin-left: auto;
              margin-top: 20px;
            }
            .filter-list {
              .title-primary {
                text-align: center;
              }
              .MuiPaper-root {
                padding: 0;
              }
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 100%;
            flex-basis: 100%;
            margin: 0px 0 0 0;
          }
        }
      }
    }
    .visa-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 100%;
            flex-basis: 100%;
            .search-filter {
              .title-primary {
                text-align: center;
              }
            }
            .ShowResponsiveDiv {
              display: none;
            }
          }
          &:last-child {
            max-width: 100%;
            flex-basis: 100%;
            margin-left: auto;
            margin: 16px 0 0 0;
            .title-primary {
              text-align: center;
            }
          }
        }
        .MuiGrid-grid-xs-6,
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 0px 0 0 0;
          .company-list {
            .company-title {
              justify-content: center;
            }
            .n-empty-list {
              margin-top: 0;
            }
          }
        }
      }
    }
    .empty-save-box {
      padding: 15px !important;
      .empty-item {
        padding: 20px 15px 15px !important;
      }
    }
    .company-list {
      .company-title {
        align-items: center !important;
        .title-primary {
          padding-top: 2px;
        }
      }
    }
    .company-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 0px 0 0 0;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          .company {
            .top-section {
              margin: 0 0 16px 0;
            }
            .body-section {
              .company-card {
                .card-left {
                  padding-right: 15px;
                }
              }
            }
          }
        }
      }
    }
    .company-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-9 {
          .company {
            .body-section {
              .company-card {
                .card-left {
                  padding-right: 0;
                  width: 100%;
                  border: 0;
                  border-bottom: 0;
                  padding-bottom: 0px;
                  margin-bottom: 0px;
                }
                .card-right {
                  margin: 0 auto;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
    .category-list {
      .title {
        text-align: center;
      }
      .categories {
        ul {
          li {
            a {
              padding: 10px 15px 10px 15px !important;
              min-height: auto !important;
              font-size: 16px !important;
            }
            + li {
              border-top: 1px solid var(--light-gray-color);
            }
          }
        }
      }
    }
    .started-middle-page {
      .faq-section {
        .MuiPaper-root {
          padding: 20px 4px 15px 15px !important;
        }
      }
      .faq-post {
        .card-content {
          padding: 16px !important;
        }
      }
    }
    .started-mainpage {
      .resource-section {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            &:first-child {
              max-width: 100%;
              flex-basis: 100%;
            }
            &:last-child {
              max-width: 100%;
              flex-basis: 100%;
              margin-left: auto;
              margin: 8px 0 0 0;
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 100%;
            flex-basis: 100%;
            margin: 8px 0 0 0;
          }
        }
      }
    }
    .resource-list {
      .resouces-cards {
        padding: 20px 15px 15px !important;
        .header-primary {
          min-height: auto !important;
        }
      }
    }
    .webinar-section {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:first-child {
            max-width: 100%;
            flex-basis: 100%;
            .title-primary {
              text-align: center;
            }
            .ShowResponsiveDiv {
              display: none;
            }
          }
          &:last-child {
            display: none;
          }
        }
        .MuiGrid-grid-xs-6,
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 16px 0 0 0;
          .webinar-list {
            margin: 0 0 30px 0;
            .web-title {
              justify-content: center;
            }
            .webinar-cards {
              padding: 20px 15px;
              .item {
                .img-left {
                  width: 100px;
                  padding-right: 15px;
                }
                .btn {
                  right: 15px;
                }
              }
            }
          }
        }
      }
      &.cal-mode {
        .MuiGrid-grid-xs-3 {
          .webinar-list {
            margin: 0;
            .title-primary {
              margin-bottom: 0;
              font-size: 22px;
              svg {
                margin: -1px 0 0 0;
                width: 16px;
              }
            }
            .MuiGrid-root {
              display: none;
            }
          }
          > div {
            &:nth-child(2) {
              display: none;
            }
          }
        }
        .MuiGrid-grid-xs-9,
        .MuiGrid-grid-xs-6 {
          margin-top: -48px;
          .web-title {
            justify-content: center;
            .title-primary {
              font-size: 22px;
              line-height: 18px;
              padding-top: 8px;
            }
            .mode {
              margin-left: 15px !important;
              button {
                width: auto;
              }
            }
          }
        }
      }
    }
    .resource-mainpage {
      .resource-section {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            max-width: 100%;
            flex-basis: 100%;
          }
          .MuiGrid-grid-xs-6 {
            max-width: 100%;
            flex-basis: 100%;
            margin: 20px 0 0 0;
          }
        }
      }
    }
    .student-mainpage {
      .student-section {
        padding-top: 16px;
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            max-width: 100%;
            flex-basis: 100%;
            &:last-child {
              display: none;
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 100%;
            flex-basis: 100%;
            margin: 16px 0 0 0;
          }
        }
      }
    }
    .country-mainpage {
      .country-section {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 20px 0 0 0;
          .country-right {
            .MuiGrid-root {
              > div {
                max-width: 100%;
                flex-basis: 100%;
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
    .tools-dashboard {
      .MuiGrid-root {
        .MuiGrid-grid-xs-7 {
          max-width: 100%;
          flex-basis: 100%;
          .title-primary {
            text-align: center;
          }
        }
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 20px 0 0 0;
          .tools-eventlist {
            .title-primary {
              text-align: center;
            }
          }
        }
      }
    }
    .CareerGameplanDashboard {
      .btn-test {
        text-align: center;
        justify-content: center;
        margin: 0 0 16px 0;
        button {
          margin: 0 auto;
        }
      }
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;

          div.title-primary {
            text-align: center;
          }
        }
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 20px 0 0 0;
          div.title-primary {
            text-align: center;
          }
          .career-middle-card {
            .card-content {
              padding: 20px 15px;
            }
          }
        }
      }
    }
    .tools-gameplan {
      .btn-test {
        margin: 0 0 30px 0;
        button {
          margin: 0 auto;
        }
      }
      > .MuiGrid-root {
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 0;
          .btn-next {
            margin: 25px 0 25px 0;
            &.getstartedbtn {
              margin-top: 25px;
            }
          }
        }
      }
      .gameplan-test-loader {
        .d-flex {
          > div {
            width: 25px !important;
          }
        }
      }
    }
    .tools-checklist {
      .MuiGrid-container {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
          .title-primary {
            text-align: center;
          }
        }
        .MuiGrid-grid-xs-6 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 8px 0 0 0;
        }
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
    .tools-calendar {
      .btn-test {
        margin: 0 0 30px 0;
        button {
          margin: 0 auto;
        }
      }
      .MuiGrid-container {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
          .title-primary {
            text-align: center;
          }
        }
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 20px 0 0 0;
        }
      }
    }
    .NotificationWrap {
      .btn-test {
        margin: 0 0 16px 0;
        justify-content: center;
        button {
          margin: 0;
        }
      }
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
          .title-primary {
            text-align: center;
          }
        }
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 20px 0 0 0;
          .title-primary {
            text-align: center;
          }
        }
      }
    }
    .MuiDialog-container {
      .MuiDialog-paper {
        margin: 15px auto;
        max-height: calc(100% - 40px);
        width: 92%;
        max-width: 480px;
        padding: 0;
        .MuiDialogContent-root {
          padding: 24px !important;
          width: 100% !important;
          margin: 0 auto;
          min-width: auto;
          .MuiDialogContentText-root {
            padding: 0 !important;
          }
          .modal-title {
            font-size: 22px;
            max-width: 270px;
            line-height: 24px;
            margin: 0 auto 8px;
            + svg {
              right: -5px;
              top: -2px;
            }
          }
          .closeModal {
            right: -5px;
            top: -2px;
            svg {
              right: 0 !important;
            }
          }
          .text-para {
            max-width: 280px;
          }
          .got-it-button {
            margin: 15px 0 0 0;
          }
          .modal-btn-wrap {
            margin: 30px 0 0 0;
          }
        }
      }
    }
    .save-search-modal {
      .MuiDialogContent-root {
        overflow: auto !important;
      }
    }
    .feedback-dialog {
      .model-body {
        margin: 0px auto 0;
        .rating-box {
          .custom-rating {
            img {
              width: 38px;
            }
          }
        }
        .friend-refer {
          ul {
            a,
            li {
              &.point {
                text-decoration: none;
                height: 22px;
                width: 22px;
                line-height: 22px;
                font-size: 13px;
              }
            }
          }
        }
        .about-box {
          textarea {
            min-height: 80px;
          }
        }
        .like {
          .sub-header-primary {
            font-size: 15px;
            line-height: 15px;
          }
        }
      }
    }
    .create-topic-dialog {
      .topic-dialog-content {
        margin-top: 15px;
        .image-upload {
          margin: 0 0 30px 0;
        }
      }
      .group-profile {
        .description {
          > div {
            width: 100%;
          }
        }
      }
      .mb-50 {
        margin-bottom: 30px;
      }
      .step2 {
        margin: 15px auto 0 !important;
        .MuiFormGroup-root {
          .MuiTypography-root {
            margin: 10px 0 30px;
          }
        }
      }
      .step3 {
        .member-box {
          .notify-dropdown {
            margin: 15px auto 15px;
          }
          .members {
            .info-panel {
              padding: 10px 10px;
              .info-panel-card {
                padding: 15px 15px 15px 15px;
              }
            }
          }
        }
      }
    }
    .profile-accessibility-popup {
      .MuiPaper-root {
        .text-para {
          margin: 0 0 20px;
        }
        .customized-button {
          width: 100%;
          max-width: 320px;
          button {
            width: 100%;
            min-width: auto;
          }
        }
      }
    }
    .jobs-experience-popup {
      .MuiDialog-paper {
        overflow: auto;
      }
    }
    .create-event-popup {
      .d-flex {
        margin-top: 15px !important;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        label.form-label {
          width: 100% !important;
        }
      }
      .react-datepicker-wrapper {
        margin-bottom: 0;
        width: 100%;
      }
      .NewEventForm {
        .StartEndTime {
          > div {
            width: 48%;
          }
        }
      }
    }
    .sync-events-popup {
      .MuiDialog-paper {
        min-width: auto;
      }
      .MuiPaper-root {
        .text-para {
          margin: 0 0 20px;
        }
      }
    }
    .notifaction-managesetting {
      .notification-switch {
        justify-content: center;
        margin-top: -20px;
        margin-bottom: 15px;
        padding-right: 0;
      }
      .MuiGrid-container {
        .MuiGrid-grid-xs-3 {
          max-width: 100%;
          flex-basis: 100%;
          .title-primary {
            text-align: center;
          }
        }
        .MuiGrid-grid-xs-9 {
          max-width: 100%;
          flex-basis: 100%;
          margin: 0px 0 0 0;
          .title-primary {
            text-align: center;
          }
        }
      }
    }
    .saved-searches {
      .search-list {
        .MuiGrid-spacing-xs-3 {
          .MuiGrid-grid-xs-9 {
            max-width: 100%;
            flex-basis: 100%;
            padding: 0;
            order: 2;
            margin: 20px 0 0 0;
            .title-primary {
              text-align: center;
            }
          }
          .MuiGrid-grid-xs-3 {
            max-width: 100%;
            flex-basis: 100%;
            order: 1;
            padding-top: 0;
            .title-primary {
              text-align: center;
            }
          }
        }
      }
    }
    .get-app-popup {
      .MuiPaper-root {
        .text-para {
          margin: 0 0 20px;
        }
        .Appimg {
          margin: 0 0 20px;
          a {
            img {
              width: 100%;
            }
            + a {
              margin-left: 15px;
            }
          }
        }
        form {
          .ReceiveLink {
            margin: 0 0 20px;
            > .d-flex {
              > div {
                .MuiFormControl-root {
                  > div {
                    input {
                      padding: 12px 15px !important;
                      min-height: 44px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .topic-menu-dialog {
      .menu-dialog-content {
        margin-top: 15px;
      }
    }
    .webinar-dialog {
      .webinar-dialog-content {
        .description {
          max-width: 380px;
          margin: 10px auto 20px;
        }
      }
    }
    .Toastify {
      justify-content: center !important;
      .Toastify__toast-container--bottom-center {
        width: 96% !important;
        margin: 0 auto !important;
        left: auto;
        min-width: auto !important;
        transform: none;
        .Toastify__toast {
          padding: 15px 20px 15.3px 15px;
        }
      }
    }
    .country-right {
      .intro {
        padding: 20px 15px !important;
        .card-content {
          > div {
            + div {
              margin-top: 15px !important;
            }
          }
        }
      }
      .overview-card {
        padding: 20px 15px !important;
      }
    }
    .jobs-visa-popup .MuiPaper-root,
    .importance-popup .MuiPaper-root .MuiDialogContent-root,
    .background-popup .MuiPaper-root .MuiDialogContent-root {
      overflow-y: auto;
    }
  }
  .MuiDialog-root {
    overflow: hidden;
  }
  body.checklist-dnd {
    li {
      .card-content {
        padding: 15px 15px 15px 15px !important;
        .checklist-task {
          flex-wrap: wrap;
        }
      }
    }
  }
  body {
    .collapsible-view {
      box-shadow: var(--card-box-shadow) !important;
      border-radius: 8px;
      overflow: hidden;
      .title-primary {
        display: flex;
        align-items: center;
        background: #fff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        padding: 15px 15px 8px !important;
        font-size: 18px !important;
        border-radius: 8px 8px 0 0;
        margin-bottom: 0 !important;
        line-height: normal !important;
        .collapse-icon {
          margin-left: auto;
          display: flex;
          align-items: center;
          position: relative;
          top: -2px;
          width: 24px;
          height: 24px;
          justify-content: center;
        }
      }
      .search-card,
      .topics-categories {
        border-radius: 0px 0px 8px 8px !important;
        box-shadow: 0 15px 14px 3px rgba(209, 221, 229, 0.4) !important;
        border-top: 1px solid #e5e6e6;
        padding: 0 !important;
        height: 0;
        overflow: hidden !important;
        transition: all 0.3s;
        margin: 0 !important;
        .card-content {
          max-width: 100% !important;
          padding: 16px 16px !important;
        }
      }
      &.open {
        .search-card,
        .topics-categories {
          height: 100%;
          overflow: visible;
          transition: all 0.3s;
        }
        .collapse-icon.arrow {
          transform: rotate(180deg);
        }
      }
      .topics-categories {
        .categories {
          max-height: 300px;
          overflow-y: auto;
          background: #fff;
          .card-content {
            padding: 0 !important;
          }
          .info-panel-card {
            margin: 0 !important;
            border-radius: 0 !important;
            box-shadow: none !important;
            border-bottom: 1px solid var(--light-gray-color);
          }
        }
      }
    }
    .featured-jobs-list {
      .collapsible-view {
        .card-wrapper {
          .MuiPaper-root {
            padding: 0 !important;
          }
        }
      }
    }
    .company-mainpage {
      .collapsible-view {
        .search-title {
          .title-primary {
            a:first-child {
              display: flex;
              margin: 0px 0 0 auto;
              &.ngray-link {
                margin: -4px 0 0 8px;
              }
            }
          }
        }
        &.search-list {
          .card-wrapper {
            display: none;
          }
        }
        .MuiGrid-root {
          margin: 0;
          background: #fff;
          width: 100%;
          height: 0;
          max-height: 368px;
          overflow-y: auto;
          transition: all 0.3s;
          > .MuiGrid-item {
            padding: 0;
            .search-card {
              border-radius: 0 !important;
            }
          }
        }
        &.open {
          .MuiGrid-root {
            height: 100%;
            transition: all 0.3s;
          }
          .card-wrapper {
            display: block;
            .search-card {
              border-radius: 0 !important;
              padding: 16px 16px 14px !important;
              .card-content {
                padding: 0 !important;
              }
            }
          }
        }
        &.position-filter {
          .MuiGrid-root {
            max-height: initial;
          }
        }
      }
    }
    .visa-mainpage {
      .collapsible-view {
        .search-title {
          .title-primary {
            a:first-child {
              display: flex;
              margin: 0px 0 0 auto;
              &.ngray-link {
                margin: -4px 0 0 8px;
              }
            }
          }
        }
        &.search-list {
          .card-wrapper {
            display: none;
          }
        }
        .MuiGrid-root {
          margin: 0;
          background: #fff;
          width: 100%;
          height: 0;
          overflow-y: auto;
          transition: all 0.3s;
          > .MuiGrid-item {
            padding: 0;
            .search-card {
              border-radius: 0 !important;
            }
          }
        }
        &.open {
          .MuiGrid-root {
            height: 100%;
            transition: all 0.3s;
            max-height: 380px;
          }
          .card-wrapper {
            display: block;
            .search-card {
              border-radius: 0 !important;
              padding: 16px 16px 14px !important;
              .card-content {
                padding: 0 !important;
              }
            }
          }
        }
        &.position-filter {
          .MuiGrid-root {
            max-height: initial;
          }
        }
      }
    }
    .category-list {
      &.collapsible-view {
        > div {
          background: #fff;
          height: 0;
          overflow: hidden;
          transition: all 0.3s;
          .search-box {
            padding: 15px 15px;
            margin: 0 !important;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd !important;
          }
          .categories {
            box-shadow: none !important;
            border-radius: 0 0 0 0 !important;
            max-height: 253px;
            overflow-y: auto;
            margin: 0 !important;
            ul {
              li {
                a {
                  border-radius: 0 !important;
                }
              }
            }
            .category-item,
            .checklist-card {
              border-radius: 0 !important;
            }
          }
        }
        &.open {
          > div {
            height: 100%;
            overflow: visible;
            transition: all 0.3s;
            .collapse-icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  .featured-jobs-list {
    .MuiGrid-grid-xs-9 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  #root {
    .country-mainpage {
      .serach-country {
        max-width: 100%;
        margin-bottom: 16px;
      }
      .country_listing {
        background: #fff;
        ul {
          max-height: 300px;
          overflow-y: auto !important;
          li {
            width: 100%;
            padding: 0;
            margin: 0;
            a {
              box-shadow: none !important;
              border-radius: 0 !important;
              display: block;
            }
            + li {
              border-top: 1px solid var(--light-gray-color);
            }
          }
        }
      }
      .MuiGrid-grid-md-6 {
        width: 100%;
        + .MuiGrid-grid-md-6 {
          margin-top: 16px;
        }
      }
      .selected-contry-content {
        .MuiGrid-container {
          .MuiGrid-grid-md-10 {
            .MuiGrid-container {
              .MuiGrid-grid-md-12 {
                width: 100%;
              }
            }
          }
        }
      }
      .country-content-wrapper {
        .MuiGrid-container {
          .MuiGrid-grid-md-6 {
            max-width: 100%;
            flex-basis: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  body {
    .onboarding-layout {
      .MuiGrid-container {
        .right-sidebar {
          max-width: 100%;
          flex-basis: 100%;
          overflow: hidden;
          height: auto;
          min-height: 100vh;
          .onboarding-wrapper {
            padding: 16px 16px !important;
            .onboarding-header {
              margin-bottom: 16px;
              .onboarding-sub-header {
                margin: 0 0 16px 0;
              }
              .title {
                font-size: 22px;
                line-height: 22px;
              }
              .subtitle {
                font-size: 16px;
                margin: 8px 0 0 0;
              }
            }
            .share-content {
              padding: 24px 24px 24px 24px;
            }
            .social-links {
              flex-wrap: wrap;
              justify-content: center;
              margin-top: 24px;
              ul {
                margin: 0 0 15px 0;
                li {
                  width: auto;
                  height: auto;
                  svg {
                    font-size: 30px;
                  }
                }
              }
            }
            .login-layout {
              max-width: 300px;
              margin: 0 auto;
              > div {
                width: 100%;
              }
            }
            form {
              max-width: 320px;
              margin: 0 auto;
              > div {
                width: 100% !important;
              }
              .textbox-label {
                width: 100% !important;
                margin: 0 0 8px 0 !important;
              }
            }
            button {
              width: 100%;
            }
            .forgot-passwordlink {
              margin: 16px 0 0 0;
              display: inline-block;
            }
            .social-login {
              h5 {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
    .custom-textbox {
      input,
      select {
        padding: 21.65px 16px 18.65px 16px;
      }
    }
    .custom-header-container,
    .header-wrapper {
      .header {
        padding: 8px 12px 8px;
        .logos {
          min-height: 40px;
          .logo {
            img {
              max-width: 120px;
              width: 120px;
            }
          }
          .university-logo {
            padding-left: 4px;
            margin-left: 4px;
            min-height: 32px;
            img {
              max-width: 80px;
              max-height: 40px;
            }
          }
        }
        .navbar {
          ul.menu {
            li {
              + li {
                margin-left: 30px;
              }
            }
          }
          ul.header-rightsidebar {
            top: 14px;
            right: 12px;
            li {
              margin: 0 0 0 4px;
              .MuiAvatar-root,
              .chat-action {
                height: 28px !important;
                width: 28px !important;
                svg {
                  font-size: 22px !important;
                }
              }
              .notifi-boxs {
                svg {
                  font-size: 22px !important;
                }
              }
              &.nprofile-icon {
                .profile-redirect-modal {
                  height: 28px;
                  width: 28px;
                  .MuiAvatar-root {
                    border-width: 2px;
                  }
                  svg {
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }
      .sub-menu {
        padding: 4px 0 4px;
        min-height: 40px;
        li {
          a {
            font-size: 0;
            line-height: normal;
            img {
              margin: 0;
            }
            span {
              font-size: 0;
              line-height: normal;
            }
          }
          &.active {
            &:before {
              width: 22px;
              height: 16px;
              bottom: -18px;
            }
            &:after {
              bottom: -18px;
            }
          }
        }
      }
    }
    .footer {
      .footer-content {
        padding: 15px 15px 15px !important;
        ul {
          li {
            &:first-child {
              .logo-content {
                .footer-logo {
                  img {
                    height: 35px;
                  }
                }
              }
              .tag-line {
                font-size: 18px;
              }
            }
            a {
              margin: 0 0 10px !important;
              font-size: 14px !important;
            }
            .footer-title {
              margin: 0px 0 15px !important;
              font-size: 18px !important;
            }
          }
        }
        .btn-close {
          top: 40px;
          right: 10px;
        }
      }
    }
    .custom-container {
      padding: 0 15px 30px !important;
      .custom-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .dashboard-content {
      .dashboard-leftsidebar {
        .boost-profile-card {
          .card-content {
            flex-wrap: wrap;
            > div {
              width: 100%;
              margin-left: 0 !important;
              + div {
                margin-top: 16px;
              }
            }
          }
        }
        .save-card {
          &.no-save-post {
            .card-content {
              padding: 16px;
            }
          }
        }
      }
      .dashboard-card {
        padding: 16px;
        margin: 0 0 16px 0;
      }
    }
    .savedposts-content {
      .infinite-scroll-component {
        > div {
          > div {
            .dashboard-card {
              padding: 15px 15px;
            }
          }
        }
      }
    }
    .boost-profile-content {
      .boost-profile-card {
        > .card-content {
          padding: 16px;
          .status-wrapper {
            .status-box {
              .img-arrow {
                left: 45%;
              }
              &.nt-someone {
                .network-arrow {
                  margin-left: 0;
                }
                .steps-img {
                  margin-top: -10px;
                }
                .info-panel-card {
                  padding: 15px !important;
                }
              }
            }
            .info-panel {
              .info-panel-card {
                padding: 15px;
                .MuiAvatar-root {
                  margin: 0 auto;
                }
                .panel-header {
                  margin-left: 0;
                  width: 100% !important;
                  padding-right: 0;
                  margin: 15px 0;
                  text-align: center;
                  .panel-title {
                    justify-content: center;
                  }
                  .panel-intro {
                    justify-content: center;
                  }
                }
                .panel-actions {
                  max-width: 100%;
                  width: 100%;
                  justify-content: center;
                  margin: 0 auto;
                }
              }
            }
            .job-wrapper {
              .boost-profile-job {
                .card-content {
                  padding: 15px;
                  flex-wrap: wrap;
                  > div {
                    width: 100%;
                    padding: 0;
                    margin: 0 !important;
                    + div {
                      margin: 10px 0 0 0 !important;
                    }
                    &:first-child {
                      input[type="text"] {
                        padding: 14.9px 8px 10.4px 20px !important;
                        font-size: 18px !important;
                      }
                    }
                  }
                  input,
                  select {
                    padding: 14.9px 8px 10.4px 8px;
                    font-size: 16px;
                  }
                  > div {
                    + div {
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
            .boost-webinar {
              margin: 72px 0 16px 0 !important;
              .step4 {
                margin: -15px 0 -15px 0;
              }
              .network-arrow {
                transform: rotate(320deg);
                top: -25px;
                right: 0px;
                max-width: 70px;
              }
              .boost-profile-webinar {
                .card-content {
                  padding: 15px !important;
                  flex-wrap: wrap;
                  justify-content: center;
                  .webinar-info {
                    text-align: center;
                    padding: 0;
                    margin: 10px 0 15px !important;
                    width: 100%;
                    .duration,
                    .user-info {
                      justify-content: center;
                    }
                  }
                  .webinar-action {
                    margin: 0 auto;
                  }
                }
              }
            }
          }
        }
      }
      .MuiGrid-grid-xs-3 {
        &:last-child {
          .card-content {
            max-width: 320px;
          }
        }
      }
    }
    .profileContent {
      .custom-container {
        form {
          .profile-containers {
            .MuiGrid-grid-xs-3 {
              max-width: 100%;
              flex-basis: 100%;
              .card-content {
                max-width: 300px;
              }
              + .MuiGrid-grid-xs-3 {
                margin: 50px 0 0 0;
              }
            }
          }
          .profile-bottom {
            button {
              margin: 30px 0 30px;
            }
          }
        }
      }
    }
    .network-content {
      .info-panel {
        .info-panel-card {
          padding: 15px;
          .card-content {
            .panel-header {
              width: calc(100% - 60px) !important;
              padding-right: 0;
            }
            .panel-actions {
              max-width: 100% !important;
              justify-content: flex-end !important;
              margin: 10px 0 0 0;
              width: 100% !important;
              button {
                + button {
                  margin-left: 15px;
                }
              }
            }
          }
        }
      }
      .MuiGrid-root {
        .title-boxs {
          justify-content: flex-start;
        }
      }
    }
    div.title-primary {
      font-size: 24px;
      line-height: 18px;
    }
    .recom-details-card {
      padding: 25px 15px !important;
      margin-bottom: 0px !important;
    }
    .right-section {
      .details {
        padding: 20px 15px !important;
      }
    }
    .empty-favorite-card {
      padding: 30px 15px;
    }
    .your-topic-page {
      .list {
        .info-panel-card {
          padding: 15px;
        }
      }
    }
    .discover-network-wrapper {
      .MuiGrid-root {
        .search-card {
          .card-content {
            margin: 0 auto;
            max-width: 280px;
            padding: 30px 15px 30px;
          }
        }
        .people-container {
          .info-panel {
            .info-panel-card {
              padding: 15px;
              .card-content {
                flex-wrap: wrap;
                align-items: flex-start;
                .panel-header {
                  width: calc(100% - 60px);
                  padding: 0;
                  .panel-title {
                    flex-wrap: wrap;
                    > div {
                      &:first-child {
                        width: 100%;
                      }
                      &.tags {
                        margin: 0 8px 4px 0;
                      }
                    }
                    + .panel-title {
                      padding-top: 0;
                    }
                  }
                }
                .panel-actions {
                  max-width: 100%;
                  margin: 4px 0 0 0;
                  width: 100%;
                  margin-left: 58px;
                  padding-left: 0 !important;
                  button {
                    + button {
                      margin-left: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .empty-middle-box {
      margin: 15px 0 15px 0;
    }
    .mytopic-mainpage {
      .my-topic-page {
        .list {
          .info-panel {
            .info-panel-card {
              padding: 15px !important;
            }
          }
        }
      }
    }
    .middle-section {
      .create-post {
        .card-content {
          .post-feed {
            input {
              padding: 21px 80px 18px 40px !important;
            }
          }
          .post-btn {
            right: 10px !important;
            button {
              width: 60px !important;
            }
          }
        }
      }
      .post-card {
        padding: 15px !important;
        .topic-post {
          .comment-section {
            .comment-text {
              input {
                padding: 14px 10px 11px;
                font-size: 14px !important;
              }
            }
          }
        }
      }
      .card-content {
        .attachment-post {
          left: 15px !important;
          .removeicon-center {
            margin: 0 0px !important;
          }
        }
        .post-feed {
          input {
            padding: 21px 110px 18px 40px !important;
            font-size: 16px !important;
          }
        }
      }
    }
    .chat-box-wrap {
      .chat-div {
        .chat-window {
          .chat-type-box {
            .attatcment {
              left: 15px;
              .removeicon-center {
                margin: 0 0px !important;
              }
            }
            .form-control {
              padding: 21px 110px 18px 40px !important;
              font-size: 16px !important;
            }
          }
        }
      }
    }
    .empty-middle-box {
      .card-content {
        padding: 30px 15px !important;
      }
    }
    .discover-mainpage {
      .MuiGrid-spacing-xs-3,
      .MuiGrid-spacing-xs-2 {
        margin: 0;
        width: 100%;
        .MuiGrid-root {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .discover-page {
        .create-btn {
          text-align: center;
          margin-bottom: 16px;
        }
        .list {
          .info-panel {
            .info-panel-card {
              padding: 15px;
              .card-content {
                align-items: flex-start;
              }
              .panel-header {
                width: calc(100% - 60px);
                padding: 0;
              }
              .panel-intro {
                .description {
                  white-space: normal;
                }
              }
              .panel-actions {
                max-width: 100%;
                justify-content: flex-end;
                margin: 10px 0 0 0;
                width: 100%;
                button {
                  + button {
                    margin-left: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .job-dashboard {
      .job-content {
        .MuiGrid-container {
          > .MuiGrid-grid-xs-6 {
            .title-boxs {
              justify-content: flex-start;
            }
            .recommanded-list {
              .MuiGrid-grid-xs-6 {
                max-width: 100%;
                flex-basis: 100%;
              }
            }
          }
        }
      }
    }
    .save-list {
      .job-title {
        justify-content: flex-start;
      }
    }
    .search-list {
      .search-list-card {
        padding: 20px 15px;
      }
    }
    .recommanded-list {
      .card-wrapper {
        .MuiPaper-root {
          padding: 20px 15px 17px !important;
        }
      }
    }
    .search-list {
      .search-list-card {
        .search-item {
          > .content {
            width: 100%;
            margin: 0 0 8px 0;
          }
          .btn-wrap {
            justify-content: flex-end;
            margin: 0;
            width: 100%;
            flex-wrap: wrap;
            > div {
              + div {
                margin-left: 8px !important;
              }
            }
          }
        }
      }
    }
    .search-mainpage {
      .job-section {
        .MuiGrid-grid-xs-6 {
          .search-list {
            margin-bottom: 16px;
            .search-top {
              flex-wrap: wrap;
              justify-content: center;
              margin-bottom: 16px;
              .title-primary {
                width: 100%;
                text-align: center;
                margin: 0 0 16px 0;
              }
            }
            .card-wrapper {
              .MuiPaper-root {
                padding: 16px 16px !important;
              }
            }
          }
        }
      }
    }
    .alert-mainpage {
      .job-alert {
        .MuiGrid-root {
          .MuiGrid-grid-xs-6 {
            .search-list {
              .card-wrapper {
                .MuiPaper-root {
                  padding: 15px !important;
                }
              }
            }
          }
        }
      }
    }
    .visa-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          &:last-child {
            .company-list {
              .card-wrapper {
                .company-card {
                  .item {
                    padding: 0 !important;
                  }
                }
              }
            }
          }
          .search-list {
            .search-card {
              .card-content {
                padding: 15px;
                .sub-header-primary {
                  + .sub-header-primary {
                    margin: 3px 0 0px 0;
                  }
                }
              }
            }
          }
        }
        .MuiGrid-grid-xs-6 {
          .company-list {
            .company-title {
              justify-content: flex-start;
            }
            .MuiGrid-spacing-xs-3 {
              margin: 0;
              width: 100%;
            }
          }
        }
        .MuiGrid-grid-xs-9 {
          .company-list {
            .company-title {
              justify-content: flex-start;
            }
            .company-map {
              height: 500px;
              div#map {
                width: 100% !important;
                height: 500px !important;
              }

              .multiple-employers-infowindow {
                max-height: 100px;
              }
            }
          }
        }
      }
    }
    .company-list {
      .company-card {
        .item {
          padding: 15px !important;
        }
      }
      .card-wrapper {
        .MuiPaper-root {
          padding: 15px !important;
        }
      }
    }
    .company-mainpage {
      .MuiGrid-root {
        .MuiGrid-grid-xs-3 {
          .search-list {
            .search-card {
              .card-content {
                padding: 15px;
              }
            }
          }
        }
      }
    }
    .service-list {
      .empty-item {
        padding: 30px 25px;
      }
    }
    .network-content {
      .recommand-page {
        .title-primary {
          width: 100%;
          margin: 0;
        }
      }
    }
    .tools-gameplan {
      > .MuiGrid-root {
        .MuiGrid-grid-xs-6 {
          .d-flex {
            > div {
              width: 100%;
              text-align: center;
            }
            .test-title {
              margin-top: 30px !important;
              font-size: 24px;
            }
            .custom-options-list {
              a {
                width: 100% !important;
                max-width: 360px;
                margin: 0 auto 15px;
              }
            }
            .mono-select-list {
              text-align: left;
            }
            .test-sub-title {
              width: 100% !important;
              max-width: 500px !important;
            }
          }
        }
      }
    }
    .NotificationWrap {
      .notify-filter {
        .filter-card {
          .card-content {
            padding: 20px 20px 30px;
          }
        }
      }
      .info-panel {
        .card-wrapper {
          .info-panel-card {
            padding: 15px 15px 15px 15px !important;
            .card-content {
              flex-wrap: wrap;
              align-items: flex-start;
              .panel-header {
                width: calc(100% - 60px);
                padding-right: 0;
              }
              .panel-actions {
                width: 100%;
                margin: 10px 0 0 0;
                max-width: 100%;
                justify-content: flex-start;
                margin: 8px 0px 0 60px;
                button {
                  + button {
                    margin-left: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .your-topic-page {
      .list {
        .info-panel-card {
          padding: 15px 15px 15px 15px !important;
        }
      }
    }
    .filter-group {
      .filter-item {
        .custom-summary {
          padding: 20px 15px 13px 15px !important;
        }
        .custom-drop {
          .custom-details {
            ul {
              li {
                padding: 10px 15px !important;
              }
            }
          }
        }
      }
    }
    .new-msg-dialog {
      .dialog-content {
        margin-top: 10px;
        .chat-list {
          margin: 10px 0 0 0 !important;
          .info-panel {
            padding: 10px 15px;
            .info-panel-card {
              padding: 15px 15px 15px 15px;
              margin: 0 auto 0px;
              .card-content {
                .panel-header {
                  width: calc(100% - 60px) !important;
                  padding-right: 0;
                }
                .panel-actions {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
    .dialogue-back-btn {
      margin: -4px 0 0 0 !important;
      font-size: 16px !important;
      width: 14px !important;
      + span {
        font-size: 18px;
        line-height: 18px;
      }
    }
    .calendar-card {
      .card-content {
        padding: 15px 15px !important;
        .fc-header-toolbar {
          .fc-toolbar-chunk {
            > div {
              .fc-toolbar-title {
                font-size: 22px !important;
              }
            }
          }
        }
        .view-header {
          .cal-day-header {
            .fc-scrollgrid-sync-inner {
              .fc-col-header-cell-cushion {
                white-space: nowrap;
                width: 50px;
                overflow: hidden;
                margin: 0 auto;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .fc-daygrid-body {
          height: auto !important;
        }
        .fc {
          .fc-daygrid-day-number {
            font-size: 16px !important;
          }
          .fc-day-today {
            .fc-daygrid-day-number {
              font-size: 14px;
              width: 34px !important;
              height: 34px !important;
              padding: 4px 0 0 0 !important;
            }
          }
        }
      }
    }
    .webinar-section {
      &.cal-mode {
        .MuiGrid-grid-xs-9,
        .MuiGrid-grid-xs-6 {
          .web-title {
            padding-left: 80px;
          }
        }
      }
    }
    .save-changes-popup .MuiPaper-root .MuiDialogContent-root {
      overflow: auto;
    }
    .popover-wrapper .MuiPopover-paper {
      max-width: 300px;
      border-radius: 10px;
    }
    .collapsible-view {
      box-shadow: var(--card-box-shadow) !important;
      border-radius: 8px;
      overflow: hidden;
      .title-primary {
        display: flex;
        align-items: center;
        background: #fff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        padding: 15px 15px 10px;
        font-size: 18px !important;
        border-radius: 8px 8px 0 0;
        margin-bottom: 0 !important;
        line-height: normal !important;
        .collapse-icon {
          margin-left: auto;
          display: flex;
          align-items: center;
          position: relative;
          top: -2px;
        }
      }
      .search-card,
      .topics-categories {
        border-radius: 0px 0px 8px 8px !important;
        box-shadow: 0 15px 14px 3px rgba(209, 221, 229, 0.4) !important;
        border-top: 1px solid #e5e6e6;
        padding: 0 !important;
        height: 0;
        overflow: hidden !important;
        transition: all 0.3s;
        margin: 0 !important;
        .card-content {
          max-width: 100% !important;
          padding: 16px 16px 16px !important;
        }
      }
      &.open {
        overflow: visible;
        .search-card,
        .topics-categories {
          height: 100%;
          overflow: visible !important;
          transition: all 0.3s;
        }
        .collapse-icon.arrow {
          transform: rotate(180deg);
        }
      }
      .topics-categories {
        .categories {
          max-height: 300px;
          overflow-y: auto;
          .card-content {
            padding: 0 !important;
          }
          .info-panel-card {
            margin: 0 !important;
            border-radius: 0 !important;
            box-shadow: none !important;
            border-bottom: 1px solid var(--light-gray-color);
          }
        }
      }
    }
    .company-mainpage {
      .collapsible-view {
        .search-title {
          .title-primary {
            a:first-child {
              display: flex;
            }
          }
        }
        .MuiGrid-root {
          margin: 0;
          background: #fff;
          width: 100%;
          height: 0;
          max-height: 368px;
          overflow-y: auto;
          > .MuiGrid-item {
            padding: 0;
            .search-card {
              border-radius: 0 !important;
            }
          }
        }
        &.open {
          .MuiGrid-root {
            height: auto;
          }
        }
        &.position-filter {
          .MuiGrid-root {
            max-height: initial;
          }
        }
      }
    }
    .category-list {
      &.collapsible-view {
        > div {
          background: #fff;
          height: 0;
          overflow: hidden;
          transition: all 0.3s;
          .search-box {
            padding: 15px 15px;
            margin: 0 !important;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd !important;
          }
          .categories {
            box-shadow: none !important;
            border-radius: 0 0 0 0 !important;
            max-height: 253px;
            overflow-y: auto;
            margin: 0 !important;
            .category-item,
            .checklist-card {
              border-radius: 0 !important;
              border-top: 1px solid var(--light-gray-color);
            }
          }
        }
        &.open {
          > div {
            height: 100%;
            overflow: visible;
            transition: all 0.3s;
            .collapse-icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .sso-popup {
      .sso-popup-content {
        padding: 30px;
        center {
          font-size: 18px !important;
        }
        .university-form {
          center {
            > div {
              width: 100% !important;
            }
            > button {
              width: 100% !important;
            }
          }
        }
      }
    }
    #root {
      .country-mainpage {
        .selected-contry-content {
          > .MuiGrid-root {
            > .MuiGrid-grid-md-2 {
              max-width: 100%;
              flex-basis: 100%;
            }
            > .MuiGrid-grid-md-10 {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
          .cities_wrapper {
            h3 {
              margin: 0px 0 15px 0;
            }
          }
          .overview-wrapper {
            .overview-content {
              padding: 8px;
              div {
                font-size: 18px;
                line-height: 20px;
              }
            }
          }
          .country-services-wrapper {
            margin-top: 20px;
            ul {
              margin: 0 px -5px;
              li {
                width: 50%;
                margin-bottom: 15px;
                padding: 0 5px;
              }
            }
          }
        }
      }
    }
    .title-withbutton {
      flex-wrap: wrap;
      .title-primary {
        white-space: normal !important;
        font-size: 18px;
        line-height: 18px !important;
        width: 100%;
        margin: 0 0 15px 0;
      }
      .pending-request {
        width: 100%;
        margin: 0;
      }
    }
  }
  .newnotification-dialogue {
    padding: 16px;
    width: 100%;
    > .row {
      margin-bottom: 16px;
      flex-wrap: wrap;
      p {
        margin: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 8px;
      }
      button {
        margin: 0 auto 0 !important;
      }
      > div {
        margin: 0 0 0 auto !important;
        position: absolute;
        top: 15px;
        right: 5px;
      }
    }
    .info-panel-card {
      padding: 16px !important;
      .card-content {
        align-items: flex-start !important;
        .header-custom-width {
          width: calc(100% - 50px) !important;
          margin: 0 !important;
          box-sizing: border-box;
          padding-left: 15px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .progressbar-wrapper {
    width: 100%;
    padding: 0 15px;
    .RSPBprogressBar {
      width: 100% !important;
    }
  }
  .saved-jobs {
    .saved-jobs-list {
      .card-wrapper {
        .MuiPaper-root {
          .search-item {
            flex-wrap: wrap;
            align-items: flex-start;
            .avtar {
              margin-right: 8px;
            }
            .content {
              margin-right: 0;
              width: calc(100% - 58px);
            }
            .card-actions-wrapper {
              margin-left: 58px;
              margin-top: 4px;
              padding-left: 0;
            }
            .apply-btn {
              margin-left: 60px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .search-list {
    .search-list-card {
      .search-item {
        .content {
          width: 100% !important;
        }
        .apply-btn {
          justify-content: flex-end;
          text-align: right;
        }
      }
    }
  }
  .search-mainpage {
    .job-section {
      .MuiGrid-grid-xs-6 {
        .search-list {
          .card-wrapper {
            .MuiPaper-root {
              .search-item {
                flex-wrap: wrap;
                align-items: flex-start;
                .avtar {
                  margin-right: 8px;
                }
                .content {
                  margin-right: 0;
                  width: calc(100% - 58px);
                  max-width: 100%;
                  .header-primary {
                    flex-wrap: wrap;
                    line-height: normal;
                    padding-top: 2px;
                    margin: 0 0 2px 0;
                    span {
                      &:first-child {
                        width: 100%;
                        display: block;
                        text-overflow: initial;
                        white-space: normal;
                      }
                    }
                    .tag-item {
                      margin: 0 8px 4px 0;
                    }
                  }
                  + .card-actions-wrapper {
                    margin-left: 58px;
                    padding-left: 0;
                    margin-top: 4px;
                  }
                }
                .apply-btn {
                  margin-left: 60px;
                  margin-top: 10px;
                }
                > .flex {
                  margin-left: 58px;
                  padding-left: 0;
                  margin-top: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
  .alert-mainpage {
    .job-alert {
      .MuiGrid-root {
        .MuiGrid-grid-xs-6 {
          .search-list {
            .search-top {
              margin-bottom: 15px;
              flex-wrap: wrap;
              justify-content: center;
              .title-primary {
                width: 100%;
                text-align: center;
              }
            }
            .card-wrapper {
              .MuiPaper-root {
                .search-item {
                  flex-wrap: wrap;
                  align-items: flex-start;
                  .avtar {
                    margin-right: 10px;
                  }
                  .content {
                    width: calc(100% - 60px);
                    .header-primary {
                      flex-wrap: wrap;
                      line-height: normal;
                      .inline-title {
                        display: block !important;
                      }
                      > span:first-child {
                        width: 100%;
                        margin: 0 0 2px 0;
                      }
                      .tag-item {
                        margin: 0 8px 4px 0;
                      }
                    }
                    + .card-actions-wrapper {
                      margin-left: 60px;
                      padding-left: 0;
                      margin-top: 4px;
                    }
                  }
                  .apply-btn {
                    margin-left: 60px;
                    margin-top: 4px;
                  }
                  > .flex {
                    margin-left: 60px;
                    padding-left: 0;
                    margin-top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .company-list {
    .card-wrapper {
      .MuiPaper-root {
        padding: 15px;
        .company-item {
          flex-wrap: wrap;
          align-items: flex-start;
          .content {
            width: calc(100% - 60px);
          }
          .pentition {
            text-align: left;
            margin-left: 60px;
          }
          .view {
            width: 100%;
            margin: 10px 0 0 auto;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
  .company-mainpage {
    .MuiGrid-root {
      .MuiGrid-grid-xs-9 {
        .top-section {
          flex-wrap: wrap;
          justify-content: center;
          .company-title {
            width: 100%;
            text-align: center;
            margin: 0 0 16px 0;
            justify-content: center;
            .title-primary {
              width: auto;
            }
          }
          .custom-tab {
            width: 100%;
            text-align: center;
          }
        }
        .body-section {
          .company-card {
            .card-left {
              .card-top {
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 0 16px 0;
                .card-title {
                  width: 100%;
                  text-align: center;
                  margin: 0 0 16px 0;
                }
                .sort-by {
                  margin: 0 auto;
                }
              }
              .visa-info {
                .petition-header {
                  .petition-info {
                    flex-wrap: wrap;
                    padding: 15px 15px;
                    .PrimaryDiv {
                      width: 100%;
                    }
                    > .header-primary {
                      width: 100%;
                      margin: 5px 0 0 0 !important;
                    }
                  }
                }
              }
            }
            .visa-info {
              .search-list {
                .MuiPaper-root {
                  padding: 15px 15px 15px 15px !important;
                  .search-item {
                    flex-wrap: wrap;
                    align-items: flex-start;
                    > .content {
                      width: calc(100% - 60px);
                      .header-primary {
                        flex-wrap: wrap;
                        .inline-title {
                          display: block !important;
                          line-height: 18px;
                        }
                        .tag-item {
                          margin: 2px 0 2px 0;
                        }
                      }
                    }
                    > .flex {
                      margin-left: 60px;
                      margin-top: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .webinar-list {
    .empty-mw-card {
      .empty-item {
        .suggestion {
          flex-wrap: wrap;
          .arrow-icon {
            right: 30px !important;
            transform: rotate(-45deg) !important;
          }
          > div {
            width: 100%;
            text-align: center;
            .MuiAvatar-root {
              margin: 0 auto 10px;
            }
          }
          .apply-btn {
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
  .webinar-section {
    .MuiGrid-root {
      .MuiGrid-grid-xs-6 {
        .webinar-list {
          margin: 0 0 30px 0;
          .web-title {
            justify-content: center;
            flex-wrap: wrap;
            .title-primary {
              width: 100%;
              text-align: center;
            }
            .mode {
              margin: 16px 0 0 0 !important;
            }
          }
          .webinar-cards {
            .item {
              flex-wrap: wrap;
              align-items: flex-start;
              .img-left {
                width: 80px !important;
              }
              .sub-item {
                width: calc(100% - 95px) !important;
              }
              .btn {
                position: initial;
                margin-left: 80px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  body {
    #root {
      .resource-list {
        .resource-middle-card {
          .resource-item {
            flex-wrap: wrap;
            .avtar {
              width: 100% !important;
              margin: 0 !important;
              height: auto !important;
              min-height: 120px;
              &.video-image {
                img {
                  &:first-child {
                    height: auto !important;
                  }
                }
                video {
                  width: 100% !important;
                  height: auto !important;
                }
              }
              &.normal-image {
                padding: 15px 0 0 0;
              }
            }
            > .content {
              width: 100%;
              padding: 15px 15px;
              max-width: 100%;
              .header-primary {
                width: 100%;
                max-width: 100%;
              }
            }
            .apply-btn {
              margin: 0px 15px 15px auto;
            }
          }
        }
      }
    }
  }
  body {
    #root {
      .service-list {
        .service-middle-card {
          .service-item {
            padding: 15px !important;
            flex-wrap: wrap;
            .avtar {
              margin: 0 15px 0 0 !important;
              max-width: 80px;
              img {
                width: 80px !important;
              }
            }
            > .content {
              width: calc(100% - 95px) !important;
              padding: 0 0 0 0px !important;
            }
            .apply-btn {
              width: 100% !important;
              margin-left: 95px !important;
              margin-top: 10px !important;
            }
          }
        }
      }
    }
  }
  body {
    .tools-checklist {
      .MuiGrid-grid-xs-3 {
        .checklist-card {
          .checklist-items {
            li {
              a {
                padding: 18px 15px 10px !important;
                font-size: 18px !important;
              }
            }
          }
        }
      }
    }
    .webinars-wrapper {
      .header {
        .title {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }
  body {
    .notifaction-managesetting {
      .MuiGrid-container {
        .MuiGrid-grid-xs-9 {
          .panel-card {
            .card-content {
              .panel-info {
                flex-wrap: wrap;
                .panel-details {
                  width: 100%;
                }
                .panel-actions {
                  width: 100%;
                  .panel-action-label {
                    margin-left: 0;
                    margin-top: 10px;
                  }
                }
              }
              .events-info {
                .events-list {
                  li {
                    width: 100%;
                    margin: 0;
                  }
                }
              }
            }
          }
          .info-card {
            .card-content {
              flex-wrap: wrap;
              .panel-info {
                width: 100%;
              }
              .panel-action {
                width: 100%;
                max-width: 100%;
                margin: 10px 0 0 0;
                .MuiFormGroup-root {
                  margin-left: auto;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  body {
    .create-topic-dialog {
      .step3 {
        .member-box {
          .members {
            .info-panel {
              .info-panel-card {
                padding: 15px 15px 15px 15px;
                .panel-header {
                  width: calc(100% - 60px) !important;
                }
                .panel-actions {
                  margin-top: 10px !important;
                }
              }
            }
          }
        }
      }
    }
    .resource-list {
      .top {
        flex-wrap: wrap;
        justify-content: center !important;
        .title-primary {
          width: 100%;
          text-align: center;
          margin: 0 0 15px 0;
        }
      }
    }
    .duo-text {
      flex-wrap: wrap;
      justify-content: center;
      .calendar-icons {
        margin: 15px 0 0 0;
      }
    }
    .university-form {
      .keep-me-signin {
        flex-wrap: wrap !important;
        justify-content: center !important;
        margin: 30px auto 30px !important;
        .text-gray {
          margin: 10px 0 0 0 !important;
        }
      }
    }
  }
  .tools-checklist {
    .duo-text {
      button {
        width: auto;
        padding: 6px 10px 3px !important;
        margin-left: auto;
        margin-right: 0 !important;
      }
    }
  }
  .topic-post {
    .comment-section {
      .comment-list {
        .item {
          .details {
            .user-box {
              flex-wrap: wrap;
              small {
                margin: 0 0 5px 0 !important;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .custome-cropper {
    height: 200px !important;
  }
  .featured-jobs-list {
    .MuiGrid-grid-xs-9 {
      max-width: 100%;
      flex-basis: 100%;
    }
    .featured-job,
    .employer-job {
      flex-wrap: wrap;
      .avtar {
        margin-right: 8px !important;
        img {
          margin-right: 0 !important;
        }
      }
      .content {
        width: calc(100% - 58px);
      }
      .apply-btn {
        margin-left: 60px !important;
        margin-top: 10px;
      }
      .card-actions-wrapper {
        margin-left: 58px;
        padding-left: 0 !important;
        margin-top: 4px;
      }
    }
  }
  .top-featured-jobs-list {
    .employer-job {
      flex-wrap: wrap;
      .avtar {
        margin-right: 8px !important;
      }
      .content {
        width: calc(100% - 58px);
      }
      .apply-btn {
        margin-left: 60px !important;
        margin-top: 10px;
      }
      .card-actions-wrapper {
        margin-left: 58px;
        padding-left: 0 !important;
        margin-top: 4px;
      }
    }
  }
  .webinar-section__header {
    flex-wrap: wrap;
    justify-content: center !important;
    .webinar-section__header-heading {
      width: 100%;
      justify-content: center;
    }
  }
}

@media (max-width: 480px) {
  body {
    .custom-header-container,
    .header-wrapper {
      .header {
        .logos {
          .logo {
            img {
              max-width: 120px;
              width: 120px;
              @media (max-width: 360px) {
                max-width: 100px;
                width: 100px;
              }
            }
          }
          .university-logo {
            img {
              max-width: 80px;
              @media (max-width: 360px) {
                max-width: 48px;
              }
            }
          }
        }
      }
    }
    .boost-profile-content {
      .profile-status-card {
        .card-content {
          ul {
            li {
              a {
                justify-content: center;
                padding: 8px 8px 8px 8px;
                font-size: 16px;
                line-height: 16px;
                .MuiAvatar-root {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
    .search-list .search-list-card .search-item .btn-wrap button {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .title-boxs,
    .job-title {
      flex-wrap: wrap !important;
      .title-primary {
        width: 100%;
        text-align: center;
        margin: 0 0 8px 0;
      }
      .sort-by {
        margin: 0 auto;
      }
      a {
        margin: 0 auto !important;
        &.alert-icon {
          margin-left: 8px !important;
          margin-top: -4px !important;
        }
      }
    }
    .webinar-section {
      &.cal-mode {
        .MuiGrid-grid-xs-9,
        .MuiGrid-grid-xs-6 {
          .web-title {
            padding-left: 100px;
          }
        }
      }
    }
  }
  .webinar-section-title {
    h2 {
      font-size: 18px;
      white-space: nowrap;
    }
  }
}
