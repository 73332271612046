@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";

.linkedin-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  grid-gap: 6px;
  gap: 6px;
  width: 100%;
  &__heading {
    font-family: "TTCommons-Regular";
    margin: 5px 0 0px 4px;
    line-height: 12px;
    display: block;
    color: var(--field-label-color);
    font-size: 15px;
  }
  &__button {
    position: relative;
    background: #fff !important;
    box-shadow: var(--card-box-shadow);
    color: var(--field-label-color);
    width: auto;
    padding: 15px 23px;
    height: auto;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    border-radius: 100px;
    text-decoration: none;
    transition: all 0.3s;
    display: inline-block;
    width: 100%;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    &__icon {
      display: flex;
      align-items: center;
      height: 16px;
      img {
        height: 16px;
        margin-bottom: 4px;
      }
    }
    &__connected {
      img {
        height: 30px;
      }
    }
    &__description {
      color: var(--field-label-color);
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Regular";
      margin: 6px auto 0;
      min-width: 153px;
    }
    &__loader {
      position: absolute;
      right: 15px;
      top: 15px;
    }
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    &:focus {
      box-shadow: #000c3d 0px 0px 8px 0px !important;
      border-color: #000c3d !important;
      outline: none;
    }
  }
  &__buttonleft {
    justify-content: center;
    &__descriptionleft {
      margin: 4px 0 0 12px;
      min-width: 153px;
    }
  }
}
