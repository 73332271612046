@import "../../../styles/colors.scss";
.a-textarea {
  border: 2px solid var(--gray-outline-color);
  border-radius: 10px;
  padding: 16.65px 20px 12.65px 20px;
  box-sizing: border-box;
  background-color: #fff;
  outline: none;
  width: 100%;
  color: var(--primary-dark-color);
  letter-spacing: 0;
  font-size: 18px;
  height: 130px;
  resize: none;
  font-family: "TTCommons-Regular";
  vertical-align: middle;
  -ms-overflow-style: auto;
  cursor: auto;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--field-label-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--field-label-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--field-label-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--field-label-color) !important;
  }
  // --Focus style
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &:hover {
    border-color: #bfced8;
  }

  /* scroll styling start */
  scrollbar-width: thin !important;
  scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
  transition: all ease-in-out 500ms;

  &::-webkit-scrollbar {
    width: 7px; /* width of the entire scrollbar */
    transition: all ease-in-out 500ms;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important; /* color of the tracking area */
    border-radius: 50px;
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 10px 0 10px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(
      --light-gray-color
    ) !important; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
    height: 40px;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0;
    background-color: var(
      --gray-color
    ) !important; /* color of the scroll thumb */
  }
  /* scroll styling end */

  &__valid {
    border-color: var(--gray-color) !important;
  }
  &__error {
    text-align: center;
    padding-top: 4px;
    color: var(--secondary-color);
  }
}

.error-container {
  padding-left: 10px;
  text-align: center;
  margin-top: -2px;
  color: var(--secondary-color);
}
