.new-msg-dialog {
  &.new-chat-dialog-modal {
    .MuiDialogContent-root {
      padding: 24px;
    }
    .dialog-content {
      padding-top: 8px;
      margin: 0;
      > div{
        .MuiButton-root{
          height: 50px;
        }
      }
    }
  }
  .MuiDialogContent-root {
    padding: 50px;
    .MuiDialogContentText-root {
      padding: 0 !important;
      margin: 0 !important;
      .makeStyles-dialogTitle-8 {
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        text-align: center;
        color: var(--primary-color);
        max-width: 354px;
      }
      .jobs-tutorial__title {
        max-width: 440px;
      }
      .makeStyles-btnClose-11 {
        top: -5px;
        right: -5px;
      }
    }
  }
  .dialog-content {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    .form-group {
      max-width: 332px;
      width: 100%;
      .textbox-label,
      .input-label {
        margin: 0 0 8px 0 !important;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
      }
    }

    .group-icon {
      height: 100px;
      width: 100px;
      background: rgba(209, 221, 229, 0.3);
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .image-upload {
      display: flex;
      align-items: center;
      margin: 9px 0 0 0;
      flex-wrap: wrap;
      .remove-icon {
        flex-wrap: wrap;
        > div {
          &:first-child {
            width: 100%;
          }
        }
      }
      > div {
        width: 100%;
        &:last-child {
          margin-top: 15px;
        }
      }
      .custom-avtar {
        display: flex;
        justify-content: center;
        .MuiAvatar-root {
          height: 100px;
          width: 100px;
          display: flex;
          margin: 0 0 9px 0;
          border-radius: 100px;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            margin-right: 0px;
          }
        }
      }
      img {
        height: 15px;
        width: 15px;
        margin-right: 8px;
      }
      span {
        font-family: "TTCommons-Regular";
        font-size: 18px;
        color: var(--secondary-color);
      }
      .btn-upload {
        color: var(--secondary-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        border-bottom: 1px solid var(--secondary-color);
      }
    }
    .step2-search-network {
      width: 100%;
      max-width: 332px;
      margin: 50px auto 0;
      text-align: center;
      button {
        margin: 25px 0 0 0;
      }
    }
    .chat-list {
      margin: 40px 0 0 0 !important;
      max-height: calc(100vh - 393px);
      overflow-y: auto;
      overflow-x: hidden;
      .info-panel-card {
        max-width: 670px;
        margin: 0 auto 20px;
        .panel-header {
          width: calc(100% - 170px);
        }
        .panel-actions {
          max-width: 101px;
        }
      }
      h3.no-student {
        color: var(--primary-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}
.chat-group-dialog {
  .dialog-content {
    .form-group {
      max-width: 438px;
      .ReactTags__tags {
        padding: 19px 23px 16px 23px;
        border: 2px solid var(--gray-outline-color);
        border-radius: 45px;
        background-color: #ffffff;
        .ReactTags__selected {
          width: 100%;
          .ReactTags__tagInput {
            display: inline-block;
          }
          .ReactTags__remove {
            margin: 0 0 0 4px;
            font-size: 24px;
            vertical-align: middle;
            line-height: 12px;
            cursor: pointer;
          }
        }
      }
      + button {
        height: auto;
        padding: 20.85px 0 14.85px;
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24.3px;
        text-align: center;
        border-radius: 45px;
        width: 100%;
        max-width: 178px;
      }
    }
  }
  .chat-list {
    max-height: calc(100vh - 453px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    .info-panel-card {
      .panel-header {
        width: calc(100% - 200px) !important;
      }
      .panel-actions {
        max-width: 137px !important;
      }
    }
    h3.no-student {
      color: var(--primary-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
    }
  }
  .makeStyles-btnBack-12 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    color: var(--primary-color);
    font-family: "TTCommons-Medium";
    svg {
      height: 22px;
      width: 20px;
      margin: -6px 4px 0 0px;
    }
    .makeStyles-btnBack-12 span {
      margin: 0 !important;
    }
  }
}
