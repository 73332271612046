.sync-events-popup {
  .MuiPaper-root {
    width: 100%;
    max-width: 480px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    .MuiDialogContent-root {
      padding: 30px 30px;
      box-sizing: border-box;
      width: 100% !important;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
    .text-para {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin: 8px auto 16px;
      max-width: 100%;
      text-align: center;
      max-width: 330px;
    }
    .social-accounts-wrap {
      max-width: 326px;
      margin: 0 auto;
      .accounts {
        > label {
          color: var(--field-label-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 12px;
          display: block;
          text-align: center;
          padding: 5px 0 0 0;
          margin: 0 0 6px 0px;
        }
        button {
          margin: 0;
          > div {
            gap: initial;
            > span {
              display: block;
              text-align: center;
              padding: 5px 0 0 0;
              margin: 0 0 6px 0px;
            }
            a {
              justify-content: center;
              > span {
                margin: 4px 0 0 12px;
                min-width: 153px;
                text-align: center;
                height: auto;
              }
            }
          }
        }
        .google-sso-connect-v1 {
          justify-content: center;
          .google-sso-connect-v1-text {
            margin: 4px 0 0 12px;
            min-width: 153px;
            text-align: center;
          }
        }
        + .accounts {
          margin-top: 15px;
        }
      }
      button {
        width: 100%;
        margin: 30px 0 22px 0;
      }
    }
  }
}
