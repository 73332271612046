/* @font-face {
  font-family: "TTCommons-DemiBold";
  src: url("./assets/FinalFont/ttcommons-demibold.woff2") format("woff2"),
      url("./assets/FinalFont/TTCommons-DemiBold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Medium";
  src: url("./assets/FinalFont/ttcommons-medium.woff2") format("woff2"),
      url("./assets/FinalFont/TTCommons-Medium.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Regular";
  src: url("./assets/FinalFont/ttcommons-regular.woff2") format("woff2"),
      url("./assets/FinalFont/TTCommons-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brandon_grotesquebold";
  src: url("./assets/FinalFont/brandon_bld.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "TTCommons-DemiBold";
  src: url("./assets/fonts-preview/TT_Commons_Classic_Trial-web_DemiBold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Medium";
  src: url("./assets/FinalFont/ttcommons-medium.woff2") format("woff2"),
    url("./assets/FinalFont/TTCommons-Medium.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Regular";
  src: url("./assets/FinalFont/ttcommons-regular.woff2") format("woff2"),
    url("./assets/FinalFont/TTCommons-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoColorEmoji-Regular";
  src: url("./assets//fonts//NotoColorEmoji-Regular.ttf");
}

/* @font-face {
  font-family: "TTCommons-Medium";
  src: url("./assets/fonts-preview/TT_Commons_Classic_Trial-web_Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Regular";
  src: url("./assets/fonts-preview/TT_Commons_Classic_Trial-web_Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: "TTCommons-Regular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, "TT Commons";
}

.error-wrapper {
  text-align: center;
  h2 {
    margin: 0;
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 42px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
  }
  h3 {
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center;
    margin: 0;
  }
  .img-wrap {
    margin: 20px auto;
    max-width: 550px;
    img {
      width: 100%;
    }
  }
  button {
    max-width: 270px;
    border-radius: 45px;
    background-color: #f7645f !important;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding: 21.85px 26px 16.85px;
    height: auto;
    text-transform: capitalize;
    font-family: "TTCommons-Medium";
    margin-top: 30px;
  }
}
