.vjs-big-play-button {
  background: none !important;
  border: none !important;

  .vjs-icon-placeholder {
    &::before {
      content: url("../assets/svg/Play.svg") !important;
      top: -45px !important;
      left: -22px !important;
    }
  }
}
