.dashboard-post-modal {
  &__component {
    .MuiPaper-root {
      .MuiDialogContent-root {
        padding: 0px !important;
        .MuiDialogContentText-root {
          margin: 0 0 0 0 !important;
        }
      }
    }
  }
  &__body {
    margin-top: 25px;
  }
}
