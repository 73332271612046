.create-event-popup {
  .datepicker-wrapper,
  .timepicker-wrapper {
    label.form-label {
      text-align: center;
      display: block;
    }
  }
  .react-datepicker-wrapper {
    margin-bottom: 25px;
    width: 100%;
    input {
      width: 100%;
      padding: 20px 15px 15px 22.18px !important;
      border: 2px solid var(--gray-outline-color);
      border-radius: 45px;
      background-color: #ffffff;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      -webkit-letter-spacing: 0;
      -moz-letter-spacing: 0;
      -ms-letter-spacing: 0;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      color: var(--primary-color);
      &:focus {
        box-shadow: 0 0 8px 0px #000c3d !important;
        border-color: #000c3d !important;
      }
    }
    .mb-0 {
      margin-bottom: 0;
    }
  }
  .NewEventForm {
    max-width: 325px;
    .textbox-label {
      font-size: 15px;
      line-height: 15px;
      font-family: "TTCommons-Regular";
      color: #000485;
      justify-content: center;
      padding: 0;
      margin: 0 0 8px !important;
    }
    .form-control {
      max-width: 325px;
    }
    .StartEndTime {
      > div {
        width: 45%;
        .form-control {
          width: 100%;
        }
        + div {
          margin-left: auto;
        }
      }
    }
  }
}

.react-custom-dropdown {
  .css-1yu1e9x-control {
    border: 2px solid var(--gray-outline-color);
    border-radius: 45px;
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    color: var(--gray-text-color);
    font-family: "TTCommons-Medium";
    box-sizing: border-box;
    min-height: 60px;
    width: 100%;
    > div + div {
      svg {
        fill: var(--gray-outline-color);
      }
    }
    + div {
      border-radius: 45px;
    }
  }
}
