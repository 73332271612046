@import "../../../../styles/typographies.scss";
@import "../../../../styles/colors.scss";

.participant__container {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 12px;

  .participant__container--title {
    span {
      margin-left: 12px;
      display: block;
      color: var(--primary-dark-color);
      margin-right: 10px;
    }
  }
  .participant__container--role {
    span {
      color: var(--gray-text-color);
      display: block;
      margin-right: 0px;
      padding-bottom: 0px;
      font-size: 16px;
      @include TTCommonsRegular;
    }
  }
}
