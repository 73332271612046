.delete-saved-search-popup {
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 480px;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 30px;
      .modal-title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        + svg {
          top: -5px;
          right: -5px;
        }
      }
      .model-body {
        .description-note {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          margin: 15px 0 30px 0;
          max-width: 274px;
        }
        a.text-grey.cursor-pointer {
          color: var(--gray-text-color);
          font-size: 18px;
          letter-spacing: 0;
          line-height: 18px;
        }
        .profile-img {
          .MuiAvatar-root {
            width: 70px;
            height: 70px;
            margin-bottom: 10px;
          }
        }
        .modal-field {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          max-width: 260px;
          &.display-name {
            margin-bottom: 0px;
            font-size: 18px;
            line-height: 19px;
          }
          &.meeeting-date {
            margin: 0 0 4px 0;
          }
          &.meeeting-time {
            font-size: 18px;
            line-height: 19px;
          }
          &.meeeting-timezone {
            font-size: 18px;
            line-height: 19px;
            margin: 0 0 10px 0;
          }
          &.appointment-rate {
            margin-bottom: 15px;
          }
          &.meeting-location {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-family: "TTCommons-Medium";
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            margin-bottom: 24px;
            margin-top: 8px;
            img {
              width: 25px;
              margin: 0 auto 6px;
            }
            .zoom-link {
              color: var(--primary-dark-color);
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 15px;
              font-family: "TTCommons-Medium";
              border-bottom: 2px solid var(--primary-dark-color);
              cursor: pointer;
            }
          }
        }
      }
      .accessibility-description {
        height: 80px;
        width: 336px;
        color: var(--primary-color);
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin: 0 0 42px 0;
      }
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0;
      }
    }
  }
}
