@import "../../../styles/typographies.scss";
@import "../../../styles/colors.scss";
.profile-module-badge {
  height: 22px;
  min-width: 100px;
  border-radius: 24.5px;
  background-color: var(--icon_background);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  @media (min-width: 768px) and (max-width: 1367px) {
    padding-top: 1px;
  }
  @media (max-width: 767px) {
    height: 30px;
  }
  &.profile-module-badge__incomplete {
    background-color: $color-orange;
    .profile-module-badge__text {
      color: $color-white !important;
    }
  }

  &__text {
    color: var(--primary-dark-color);
    @include TTCommonsRegular;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
  }
  &__icon {
    margin-left: 7px;
    padding-top: 3px;
  }
}
@media (max-width: 29em) {
  .profile-module-badge {
    min-width: 130px;
    @media (max-width: 360px) {
      min-width: 120px;
    }
    &__text {
      font-size: 18px;
    }
  }
}

.incomplete-badge-accessibility {
  background-color: $color-accessible !important;
  // .profile-module-badge__text {
  //   color: $color-white;
  // }
  // .profile-module-badge__icon {
  //   svg {
  //     color: $color-white !important;
  //     fill: $color-white !important;
  //     g {
  //       stroke: $color-white !important;
  //     }
  //   }
  // }
}
