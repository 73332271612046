.onboarding-email-dialog {
  .actions {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
  .back-to-url {
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgb(209 221 229 / 40%);
    height: 50px;
    width: 158px;
    justify-content: center;
    text-decoration: none;
    color: #8094ab;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    transition: all 0.3s;
    margin-right: 20px;
    @media (max-width: 767px) {
      left: -10px;
      top: 0;
      height: 40px;
    }
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    img {
      margin: 1px -4px 0 4px;
      height: 15px;
    }
    span {
      padding-top: 6px;
    }
  }
  .MuiDialogContent-root {
    padding: 0px !important;
  }
  .onboarding-email-subtitle {
    max-width: 489px !important;
    color: var(--primary-dark-color);
    display: flex;
    font-size: 18px;
    max-width: 340px;
    margin-top: 10px;
    text-align: center;
    align-items: center;
    font-family: "TTCommons-Regular";
    font-weight: 400px;
    line-height: 25px;
    justify-content: center;
  }
  .MuiTypography-root {
    padding: 24px !important;
  }
}
