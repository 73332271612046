@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";
@import "../../../styles/typographies.scss";
.a-button {
  display: inline-flex;
  text-align: center;
  border: none;
  justify-content: center;
  cursor: pointer;
  &:focus {
    &:focus {
      opacity: 0.9;
      box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
    }
  }
}
.a-button--chat-send {
  background-color: var(--secondary-color);
  color: $color-white;
  cursor: pointer;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  font-family: "TTCommons-Medium";
  min-width: 78px;
  height: 35px;
  display: flex;
  align-items: center;
}
.a-button--chat-send--disabled {
  border-radius: 20px;
  width: 80px;
  height: 30px;
  border: none;
  color: $color-white;
  font-weight: 500;
  cursor: not-allowed;
  @include TTCommonsRegular;
  align-items: center;
  background-color: $color-grey-4;
}
.a-button--filter-network {
  display: inline-block;
  border-radius: 20px;

  border: none;
  background-color: $color-blue-2;
  color: $color-blue;
  font-weight: 500;
  cursor: pointer;
  @include TTCommonsRegular;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.a-button-profile-next {
  height: 50px;
  border-radius: 25px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  padding: 18px 20px 13px;
  box-shadow: none !important;
  overflow: hidden;
  cursor: pointer;
  background: var(--secondary-color);
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  max-width: 205px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
    transition: all 0.3s;
  }
  &:focus {
    box-shadow: 0 0 8px 0 #000c3d !important;
    border-color: #000c3d !important;
  }
}
.a-button-profile-previous {
  background: #fff !important;
  box-shadow: var(--card-box-shadow);
  color: var(--field-label-color);
  width: auto;
  padding: 18px 20px 13px;
  height: auto;
  min-height: 50px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Medium";
  border-radius: 100px;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-block;
  width: 100%;
  max-width: 205px;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 1px 14px 3px var(--gray-outline-color);
  }
}

.a-default-accessibility {
  background-color: $color-accessible !important;
  &:focus {
    box-shadow: 0 0 8px 0 #000c3d !important;
    border-color: #000c3d !important;
  }
}
.a-button-signout-accessiblity {
  background-color: $color-accessible-2 !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:focus {
    box-shadow: 0 0 8px 0 #000c3d !important;
    border-color: #000c3d !important;
  }
}

.a-button--chat-list {
  cursor: pointer;
  height: 35px;
  width: 101px;
  border-radius: 45px;
  border: none;
  background-color: var(--secondary-color);
  align-items: center;
  span {
    @include TTCommonsRegular;
    height: 15px;
    color: $color-white;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
}
.a-button--chat-list-added {
  cursor: pointer;
  height: 35px;
  width: 101px;
  border-radius: 45px;
  border: none;
  background-color: #8094ab;
  align-items: center;
  span {
    @include TTCommonsRegular;
    height: 14px;
    color: $color-white;
    margin: 0 10px 0 0;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
}
