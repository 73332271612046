$color-white: #ffffff;
$color-black: #000000;

$color-grey: #d1dde5;
$color-grey-2: #607790;
$color-grey-4: #bfced8;
$color-grey-6: #f1f4f7;
$color-grey-8: #e5eaf7;
$color-medium-gray: #8094ab;

$color-blue: #000485;
$color-blue-2: #e5eaf7;
$color-blue-4: #ececff;
$color-blue-6: #0d13ad;
$color-blue-8: #0030b5;
$color-orange: #f7645f;
$color-klien-blue: #0030b5;
$color-gulf-blue: #000485;

$color-accessible: #e5190b;
$color-accessible-2: #5a7984;
$color-accessible-4: #7491aa;

$primary-font-color: #000c3d;
