.participants-list {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 40px 30px;
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 10px;
    min-height: 50px;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      min-height: auto;
    }
    @media (min-width: 960px) {
      justify-content: initial;
    }
    &__back-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      @media (max-width: 767px) {
        position: initial;
        transform: none;
      }
      button {
        background: #ffffff;
        box-shadow: var(--card-box-shadow);
        height: 50px;
        width: fit-content;
        padding: 16px 20px 12px;
        border-radius: 100px;
        transition: all 0.3s;
        border: none;
        cursor: pointer;
        @media (max-width: 767px) {
          // padding: 12px 12px 8px;
          min-height: 35px;
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
        > div {
          display: flex;
          align-items: center;
          img {
            transform: rotate(180deg);
            margin: -3px 5px 0 0;
            width: 8px;
          }
          span {
            height: 19px;
            color: var(--field-label-color);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            font-family: "TTCommons-Medium";
          }
        }
      }
    }
    h2 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Demibold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0 0 0 0;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 600px;
      @media (min-width: 960px) {
        position: relative;
        left: 25%;
      }
    }
  }
  &__filters {
    &__header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 15px;
      &__title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
      }
      &__clear-all {
        font-size: 18px;
        height: 20px;
        color: var(--gray-text-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--gray-text-color);
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &__card {
      overflow: initial !important;
      &__hide-filters {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        h4 {
          cursor: pointer;
          margin: 0 0 0 0;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          border-bottom: 1px solid var(--primary-dark-color);
          width: fit-content;
          height: 18px;
        }
      }
      &__button {
        margin-top: 20px;
        button {
          width: 100%;
          background: var(--link-color) !important;
        }
      }
    }
  }
  &__middle {
    &__title {
      margin-bottom: 15px;
      span {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
    &__info-panel-intro {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      &__flag {
        height: 15px;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 2px;
        overflow: hidden;
        box-shadow: 0 1px 4px 0 rgba(209, 221, 229, 0.4);
        background-color: #d8d8d8;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      span {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        height: 15px;
      }
      &--active {
        span {
          color: #ffffff !important;
        }
      }
    }
  }
}
