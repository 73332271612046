@import "../../../../src/styles/custom.scss";
@import "../../../../src/styles/colors.scss";
.profile-module__footer-actions {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 15px;
}
.profile-module__footer-copyright {
  margin: 30px 0 30px 0;
  color: #8094ab;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}
