.get-app-popup {
  .MuiPaper-root {
    width: 100%;
    max-width: 480px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    .MuiDialogContent-root {
      padding: 30px 30px;
      box-sizing: border-box;
      width: 100% !important;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
    .text-para {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin: 8px auto 16px;
      max-width: 100%;
      text-align: center;
      max-width: 326px;
    }
    .text-note {
      line-height: 17px;
      margin: 0 0 8px 0px;
      font-size: 15px;
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      text-align: center;
      max-width: 326px;
    }
    .Appimg {
      align-items: center;
      margin: 0 0 24px;
      max-width: 326px;
      a {
        display: flex;
        margin: 0 8px;
        img {
          width: 100%;
          max-width: 326px;
        }
      }
    }
    form {
      max-width: 326px;
      width: 100%;
      .ReceiveLink {
        margin: 0 auto 30px;
        max-width: 326px;
        label {
          color: var(--field-label-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 12px;
          display: block;
          text-align: center;
          padding: 5px 0 0 0;
          margin: 0 0 6px 0px;
        }
        > .d-flex {
          position: relative;
          > div {
            width: 120px;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            + div {
              width: calc(100% - 120px);
              padding-left: 130px;
              position: relative;
              z-index: 1;
              flex: 0 0 100%;
            }
            .react-custom-dropdown {
              border: 2px solid var(--gray-outline-color);
              border-radius: 45px;
              background-color: #ffffff;
              > div {
                border: 0 !important;
              }
            }
            .MuiFormControl-root {
              > div {
                border: 2px solid var(--gray-outline-color);
                border-radius: 45px;
                background-color: #ffffff;
                input {
                  border: 0;
                  text-align: left !important;
                  color: var(--primary-dark-color) !important;
                  ::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: red !important;
                  }
                  ::-moz-placeholder {
                    /* Firefox 19+ */
                    color: red !important;
                  }
                  :-ms-input-placeholder {
                    /* IE 10+ */
                    color: red !important;
                  }
                  :-moz-placeholder {
                    /* Firefox 18- */
                    color: red !important;
                  }
                }
              }
            }
          }
          p.MuiFormHelperText-root.Mui-error {
            color: var(--secondary-color);
            display: flex;
            justify-content: center;
            margin: 5px 8px 0px 8px;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            line-height: 17px;
          }
        }
      }
      .send-code-button {
        text-align: center;
        margin: 0 0 30px;
        button {
          width: 100%;
        }
      }
      .text-cancel {
        text-align: center;
        text-decoration: none;
        border-bottom: 1px solid #607790;
        a {
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 10px;
          border-bottom: 1px solid var(--gray-text-color);
          text-decoration: none;
        }
      }
    }
  }
}

.get-app-success-popup {
  .MuiPaper-root {
    padding: 50px;
    width: 669px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    box-sizing: border-box;
    overflow: visible;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      overflow: visible;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
      margin: 0 0 20px;
      max-width: 354px;
    }
    .text-para {
      color: var(--primary-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin: 0 0 50px;
      max-width: 336px;
    }
    .makeStyles-btnClose-31 {
      top: -5px;
      right: -5px;
    }
    .got-it-button {
      button {
        color: #ffffff;
        font-family: "TTCommons-Regular";
        max-width: 124px;
        padding: 5px 15px 1px;
        height: auto;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
