@import "../../../../src/styles/colors.scss";
@import "../../../../src/styles/typographies.scss";
@import "../../../../src/styles/custom.scss";
.o-final-container {
  margin: 0 auto;
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    text-align: center;
    margin-bottom: 22px;
    h3 {
      margin: 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      margin-bottom: 0;
      padding-top: 4px;
    }
  }
  &__switch {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__inside {
      display: flex;
      align-items: center;
      &__label {
        margin: 0 10px 0 15px;
        @include TTCommonsRegular;
        font-size: 18px;
        line-height: 19px;
        color: var(--primary-dark-color);
        min-width: 195px;
      }
      &__info {
        height: 18px;
      }
    }
    &__preview {
      cursor: pointer;
      width: fit-content;
      display: flex;
      align-items: center;
      &__label {
        margin: 0 0 0 10px;
        @include TTCommonsRegular;
        font-size: 18px;
        line-height: 19px;
        color: var(--primary-dark-color);
      }
    }
  }
}
.o-final-container__next-button {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 15px;
  margin-bottom: 80px;
}
