.saved-services {
  &__content {
    &__loader {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }
    &__item {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }
    &__placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        margin: 20px 0 0 0;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
}
