@mixin TTCommons {
  font-family: "TT Commons";
}
@mixin TTCommonsRegular {
  font-family: "TTCommons-Regular";
}
@mixin TTCommonsMedium {
  font-family: "TTCommons-Medium";
}
@mixin TTCommonsDemiBold {
  font-family: "TTCommons-Demibold";
}
@mixin ProfileModule {
  font-family: "TTCommons-Medium";
}
