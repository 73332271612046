@import "../../../styles/breakpoints.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/typographies.scss";

.custom-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px 30px;
  &__header {
    position: relative;
    margin-bottom: 25px;
    &__title {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        margin: 0 0 65px 0;
      }
      h2 {
        color: #000c3d !important;
        font-family: "TTCommons-DemiBold";
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
    &__options {
      position: absolute;
      top: 0px;
      @media (max-width: 767px) {
        top: 50px;
      }
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;
      &__back {
        @media (max-width: 767px) {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        button {
          cursor: pointer;
          display: inline-block;
          background: #fff !important;
          box-shadow: var(--card-box-shadow) !important;
          color: var(--primary-dark-color);
          width: auto;
          padding: 16px 20px 12px;
          height: auto;
          min-height: 50px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          font-family: "TTCommons-Medium";
          border: none;
          border-radius: 100px;
          transition: all 0.3s;
          text-transform: initial;
          &:hover {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
          }
          span {
            color: var(--field-label-color);
          }
          img {
            margin: 0 10px 0 0 !important;
            height: 13px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
.dialog-content__search-input {
  max-width: 100%;
  width: 100%;
}
.filters-wrapper {
  box-shadow: var(--card-box-shadow);
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  button {
    width: 100%;
    background-color: var(--link-color) !important;
  }
}
.form-group__info {
  border-radius: 10px;
  color: var(--primary-dark-color) !important;
  border: 2px solid var(--gray-outline-color);
  background-color: #fff;
  max-width: 439px;
  margin: 0 auto;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  letter-spacing: 0;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 19px;
  font-weight: 400;
  width: 100%;
  min-height: 90px;
  resize: none;
  padding: 16px;
  overflow: hidden;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--field-label-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--field-label-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--field-label-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--field-label-color) !important;
  }
  // --Focus style
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &:hover {
    border-color: #bfced8;
  }
}
.users-list-container {
  > div:first-child {
    padding-top: 8px;
    // padding-bottom: 20px;
  }
}

.apply-filter-wrapper {
  width: 100%;
  margin-bottom: 10px;
  &__action-label {
    margin-left: 25px;
    color: $color-blue;
    font-size: 22px;
    font-family: "TTCommons-Regular";
    font-weight: 200;
  }
}
.group-create-loading-wrapper {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.recom-details-card {
  position: relative;
  margin-top: 38px;
  margin-bottom: 15px;
  > div {
    padding: 20px;
  }
}

.card-close-btn {
  box-shadow: none;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: var(--icon_background);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  img {
    width: 28px;
    height: 28px;
  }
}
