.save-changes-popup {
  .modal-title {
    color: var(--primary-color);
    font-family: "TTCommons-Medium";
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center;
    max-width: 354px;
    margin: 0 auto 42px;
    + svg {
      top: -5px;
      right: -5px;
    }
  }
  .btn {
    button {
      height: auto;
      width: auto;
      padding: 20.85px 25px 14.85px 25px;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24.3px;
      min-width: 248px;
      text-align: center;
      border-radius: 45px;
    }
  }
  .MuiPaper-root {
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    overflow: visible;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      max-width: 565px;
      padding: 50px;
      box-sizing: border-box;
      min-width: 565px;
      overflow: visible;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0;
        .makeStyles-dialogTitle-15 {
          margin: 0 0 42px;
        }
        .text-grey.cursor-pointer {
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          border-bottom: 1px solid var(--gray-text-color);
          text-decoration: none;
        }
      }
    }
  }
}
