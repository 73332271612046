.spinner-container {
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  .loader {
    position: relative;
    display: flex;
  }
  .loader:before,
  .loader:after {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    margin: 0 5px;
    border-radius: 50%;
    color: var(--primary-color);
    background: currentColor;
    box-shadow: 50px 0, -50px 0;
    animation: left 1s infinite ease-in-out;
  }
  .loader:after {
    color: #ff3d00;
    animation: right 1.1s infinite ease-in-out;
  }

  @keyframes right {
    0%,
    100% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(10px);
    }
  }

  @keyframes left {
    0%,
    100% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
}
