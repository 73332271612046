.allow-dms-modal {
  &__subtitle {
    color: var(--primary-dark-color);
    width: 100%;
    display: flex;
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
    align-items: center;
    font-family: "TTCommons-Regular";
    line-height: 19px;
    justify-content: center;
  }
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    .allow-dms-container {
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      border-radius: 100px;
      padding: 18px 20px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      position: relative;
      width: fit-content;
      &__label {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
  }
  .MuiDialogContent-root {
    .MuiDialogContentText-root {
      padding: 0 !important;
    }
  }
}
