@import "../../../styles/typographies.scss";
.accordian-title-wrapper {
  &__inside {
    display: flex;
    align-items: center;
    h2 {
      margin: 0 !important;
      font-size: 22px;
      letter-spacing: 0;
      font-family: "TTCommons-DemiBold";
      padding: 0 !important;
      line-height: 20px;
      padding-top: 4px;
      @media (max-width: 767px) {
        font-size: 20px !important;
      }
      @media (max-width: 360px) {
        font-size: 20px !important;
      }
      + div {
        margin: 0 0 0 10px;
        > div {
          &:first-child {
            font-family: "TTCommons-Medium" !important;
            height: 13px;
          }
          &:nth-child(2) {
            svg {
              margin: 0 0 0 0 !important;
              position: relative;
              font-size: 14px !important;
              width: 12px;
              height: 12px;
              g {
                stroke-width: 2px;
              }
            }
          }
        }
      }
    }
  }
}
.focused-class {
  box-shadow: #000c3d 0px 0px 8px 0px !important;
  border-color: #000c3d !important;
  outline: none;
}
@media (max-width: 29em) {
  .accordian-title-wrapper {
    padding: 20px 0;

    &__inside {
      gap: 10px;
      justify-content: normal;
      h2 {
        font-size: 24px;
      }
    }
  }
}
