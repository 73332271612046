$screen-tiny-max: 480px;
$screen-phoneH-max: 640px;
$screen-tablet-max: 768px;
$screen-medium-max: 992px;
$screen-desktop-max: 1024px;
$screen-large-max: 1200px;

// Tiny devices
@mixin xss {
  @media (max-width: #{$screen-tiny-max}) {
    @content;
  }
}

// Small devices
@mixin xs {
  @media (max-width: #{$screen-phoneH-max}) {
    @content;
  }
}

//tablet
@mixin tablet {
  @media (max-width: #{$screen-tablet-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-medium-max}) {
    @content;
  }
}

// Desktop devices
@mixin lg {
  @media (max-width: #{$screen-desktop-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-large-max}) {
    @content;
  }
}
