@import "../../../styles/colors.scss";

.o-hub {
  margin-top: 30px;
  margin-bottom: 80px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    &__title {
      font-family: "TTCommons-DemiBold";
      color: $primary-font-color;
      margin: 0 0px 20px 4px;
      display: flex;
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    &__info-icon {
      max-width: 20px;
      margin: 0 0 0 12px;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    gap: 15px;
    @media (max-width: 1366px) {
      flex-direction: column;
    }
    &__main {
      &__btn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 3px;
        span {
          margin-top: 5px;
        }
      }
    }
    &__eh_right {
      display: flex;
      position: absolute;
      right: 0px;
      @media (max-width: 1366px) {
        position: relative;
        right: auto;
      }
    }
  }
  &__body {
    margin-top: 40px;
  }
}
