.jobs-experience-popup {
  .MuiDialog-container {
    overflow-y: auto;
    padding: 32px 0;
    &.MuiDialog-scrollPaper {
      display: block;
    }
  }
  .MuiPaper-root {
    max-width: 670px;
    padding: 30px;
    overflow: visible;
    box-sizing: border-box;
    max-height: initial;
    margin: 0;
    height: auto;
    margin: 0 auto;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      overflow-y: initial;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      margin: 0 0 20px;
      max-width: 100%;
    }
    .content-modal {
      margin-bottom: 0;
      p {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 16px;
        margin: 0 0 0 0;

        span {
          color: var(--primary-dark-color);
          font-family: "TTCommons-DemiBold";
          font-weight: bold;
        }
        + p {
          margin-top: 12px;
        }
      }
    }
    .got-it-button {
      margin-top: 30px;
      button {
        width: 122px;
        height: 35px;
        background-color: var(--link-color);
      }
    }
  }
}
