.background-popup {
  .MuiPaper-root {
    max-width: 340px;
    padding: 30px;
    min-width: 340px;
    box-sizing: border-box;
    overflow: visible;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      overflow: visible;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin: 0 0 10px;
    }
    .text-para {
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin: 0 0 30px;
    }
    .got-it-button {
      button {
        width: 114px;
        height: 30px;
      }
    }
  }
}
