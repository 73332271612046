.topic-setting-content-wrapper {
  .card {
    box-shadow: var(--card-box-shadow);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
    .image-upload {
      margin-bottom: 24px;
      label {
        color: var(--field-label-color);
        font-size: 15px;
        padding: 5px 0 0 0;
        margin: 0 0 6px 0;
        line-height: 12px;
        display: block;
      }
      .MuiAvatar-root {
        width: 90px;
        height: 90px;
        margin: 0 auto 8px;
      }
      .remove-icon {
        > .removeicon-center {
          margin-left: 8px !important;
        }
      }
    }
    .field-group {
      .custom-textbox {
        max-width: 285px;
        input {
          text-align: center;
        }
      }
      .custom-textarea {
        .MuiInputBase-root {
          margin-bottom: 0;
        }
      }
    }
    .ncustom-switch {
      margin-top: 4px;
      margin-bottom: 30px;
    }
    .btns-wrraper {
      button {
        width: 100%;
        max-width: 285px;
        margin-bottom: 20px;
      }
    }
  }
}
.create-topic-dialog {
  .MuiDialog-paperWidthMd {
    width: 100%;
    max-width: 900px;
  }
  .MuiDialogContent-root {
    padding: 50px;
    width: 100% !important;
    .MuiDialogContentText-root {
      margin: 0;
      padding: 0 !important;
    }
    .makeStyles-dialogHeader-7 {
      .makeStyles-dialogTitle-8 {
        color: var(--primary-color);
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        text-align: center;
        max-width: 354px;
      }
      .makeStyles-btnClose-11 {
        top: -5px;
        right: -5px;
      }
      .makeStyles-btnBack-12 {
        top: 0;
        left: 0;
        color: var(--primary-color);
        cursor: pointer;
        display: flex;
        position: absolute;
        font-size: 18px;
        align-items: center;
        font-family: "TTCommons-Medium";
        svg {
          height: 21px;
          width: 21px;
          margin: -5px 0 0 0;
        }
      }
    }
  }
  .mb-50 {
    margin-bottom: 50px;
  }

  .step2 {
    max-width: 332px;
    margin: 50px auto 0 !important;
    text-align: center;
    .form-label {
      margin: 0 0 8px 0 !important;
      color: var(--primary-color);
      font-size: 15px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
    .textbox-label {
      margin: 0 0 8px 0 !important;
      color: var(--primary-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
    .css-15ev9ge-control {
      border: 2px solid var(--gray-outline-color);
      border-radius: 45px;
      background-color: #ffffff;
    }
    .MuiFormGroup-root {
      .MuiTypography-root {
        margin: 25px 0 44px;
      }
    }
    button {
      height: auto;
      min-width: auto;
      width: auto;
      padding: 19.85px 20px 15.85px 20px;
      border-radius: 45px;
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24.3px;
      text-align: center;
      min-width: 178px;
      margin: 0 0 15px 0;
    }
  }
  .step3 {
    margin: 0 !important;
    .new-message-filter {
      button.bg-secondary {
        background-color: var(--link-color) !important;
      }
    }
    .member-box {
      .notify-dropdown {
        text-align: center;
        max-width: 438px;
        margin: 50px auto 40px;
        .input-label {
          margin: 0 0 8px 0;
          color: var(--primary-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
        }
        .ReactTags__tags {
          padding: 19px 23px 16px 23px;
          border: 2px solid var(--gray-outline-color);
          border-radius: 45px;
          background-color: #ffffff;
          .ReactTags__selected {
            width: 100%;
            .ReactTags__tagInput {
              display: inline-block;
            }
            .ReactTags__remove {
              margin: 0 0 0 4px;
              font-size: 24px;
              vertical-align: middle;
              line-height: 12px;
              cursor: pointer;
            }
          }
        }
        button {
          height: auto;
          padding: 20.85px 0 14.85px;
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24.3px;
          text-align: center;
          border-radius: 45px;
          width: 100%;
          max-width: 178px;
        }
      }
      .members {
        max-height: calc(100vh - 453px) !important;
        overflow-y: auto;
        overflow-x: hidden;
        .info-panel {
          max-width: 670px;
          margin: 0 auto;
          .info-panel-card {
            .panel-header {
              width: calc(100% - 200px) !important;
            }
            .panel-actions {
              max-width: 137px !important;
            }
          }
        }
      }
    }
    .MuiGrid-grid-xs-3 {
      &:first-child {
        @media (max-width: 1366px) {
          max-width: 35%;
          flex-basis: 35%;
        }
        @media (max-width: 991px) {
          max-width: 50%;
          flex-basis: 50%;
        }
        @media (max-width: 600px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      &:last-child {
        @media (max-width: 1366px) {
          display: none;
        }
      }
    }
    .MuiGrid-grid-xs-6 {
      @media (max-width: 1366px) {
        max-width: 65%;
        flex-basis: 65%;
      }
      @media (max-width: 991px) {
        max-width: 50%;
        flex-basis: 50%;
      }
      @media (max-width: 600px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      .info-panel-card {
        .card-content {
          @media (max-width: 991px) {
            flex-wrap: wrap;
            align-items: flex-start;
          }
          .MuiAvatar-root {
            @media (max-width: 991px) {
              width: 50px;
              height: 50px;
              min-width: 50px;
            }
          }
          .panel-header {
            @media (max-width: 991px) {
              margin: 0;
              padding: 0 0 0 8px;
              width: calc(100% - 50px);
            }
            .panel-title {
              @media (max-width: 991px) {
                flex-wrap: wrap;
                > div {
                  &:first-child {
                    width: 100%;
                  }
                  &.tags {
                    margin: 0 0 0 0;
                    .tag-item {
                      margin: 0 8px 4px 0;
                    }
                  }
                }
              }
            }
            .panel-sub-title {
              @media (max-width: 991px) {
                margin-bottom: 4px;
              }
            }
          }
          .panel-actions {
            @media (max-width: 991px) {
              margin: 0 0 0 58px;
            }
          }
        }
      }
    }
    h3.no-users {
      color: var(--primary-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
    }
  }
}
