@import "../../../styles/colors.scss";
@import "../../../styles/typographies.scss";
.o-professional-language__footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  + hr {
    margin-top: 24px;
  }
  &__completed{
    justify-content: flex-start;
  }
  &-container {
    display: flex;
    align-items: center;
  }

  &__hr {
    margin-bottom: 30px;
    height: 1px;
    background-color: $color-grey-6;
    border: none;
    width: 100%;
  }

  &__footer-container {
    font-size: 18px;
    line-height: 16px;
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    padding-top: 3px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    > span {
      border-bottom: 2px solid var(--link-color) !important;
      margin-right: 6px;
    }
    div {
      font-size: 22px;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}
