.importance-popup {
  .MuiPaper-root {
    max-width: 340px;
    padding: 30px;
    min-width: 340px;
    overflow: visible;
    box-sizing: border-box;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      overflow: visible;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      margin: 0 0 10px;
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
    .text-para {
      margin: 0 0 15px;
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
    }
    .got-it-button {
      button {
        height: auto;
        width: auto;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        padding: 4px 12px 2px 12px;
        min-width: 114px;
        .content {
          min-width: auto;
        }
      }
    }
  }
}
