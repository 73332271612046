@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";

.custom-secondary-email {
  display: flex;
  align-items: center;
  gap: 5px;
  .secondary-email-check-circle {
    line-height: normal;
    display: flex;
    margin-top: -4px;
    height: 16px;
    svg {
      font-size: 1rem !important;
    }
  }
}
.password-section {
  padding-top: 16px;
  .heading {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    word-break: break-word;
    margin: 0;
    line-height: 19px;
    margin-top: 0;
  }
  .info {
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 15px;
    color: var(--gray-text-color);
    margin-bottom: 0px !important;
    margin-top: 8px;
  }
  .tim-link {
    font-size: 18px;
    line-height: 16px;
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    text-decoration: none;
    border-bottom: 2px solid var(--link-color) !important;
    cursor: pointer;
    padding-top: 3px;
    padding-left: 0;
    padding-right: 0;
    &:hover {
      opacity: 0.9;
    }
  }
}
.close-account {
  height: 74px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  button {
    width: 100% !important;
  }
}
.google-social-button {
  .google-label {
    font-family: "TTCommons-Regular";
    margin: 5px 0 6px 4px;
    line-height: 12px;
    display: block;
    color: var(--field-label-color);
    font-size: 15px;
  }
}

.personal-loading-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  div {
    flex-grow: 1;
    .shimmer {
      width: 100%;
    }
  }
}

.personal-form-incomplete {
  &__header {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
}

.personal-form-completed {
  &__header__profile {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    margin: 0 0 15px 0;
  }
  &__header__login {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    margin: 31px 0 15px 0;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 800px) {
      justify-content: center;
    }
    &__close-account {
      span {
        color: var(--secondary-color);
        cursor: pointer;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }
}

.profile-details__complete {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: center;
  row-gap: 15px;
  column-gap: 30px;
  > div {
    width: 265px;
  }
}

.profile-details__incomplete {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: center;
  row-gap: 15px;
  column-gap: 32px;
  > div {
    width: 47%;
    @media (max-width: 605px) {
      width: 100%;
    }
  }
}

.login-details__incomplete {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: center;
  row-gap: 15px;
  column-gap: 32px;
  > div {
    width: 47%;
    @media (max-width: 605px) {
      width: 100%;
    }
  }
}

.login-details__complete {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: center;
  row-gap: 15px;
  column-gap: 45px;
  > div {
    width: 265px;
  }
}

.network-visibility-check {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  &__icon {
    height: 17px;
    a {
      border: 2px solid #d1dde5;
      &:focus {
        border: 2px solid var(--primary-color);
      }
    }
  }
  &__text {
    display: block;
    height: 13px;
    font-family: "TTCommons-Regular";
    color: var(--gray-text-color);
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
  }
}

.profile-details-intro {
  &__label {
    color: var(--field-label-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    margin: 5px 0 6px 4px;
    line-height: 14px;
  }

  &--large {
    width: 560px !important;
    textarea {
      height: 70px;
    }
  }
  &--small {
    width: 265px;
    textarea {
      height: 100px;
    }
  }
}
