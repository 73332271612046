.profile-accessibility-popup {
  .MuiPaper-root {
    max-width: 480px;
    padding: 30px;
    box-sizing: border-box;
    width: 100%;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      overflow: visible;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .change-passwords-form,
    .change-email-form {
      max-width: 326px;
      margin: 15px auto 0;
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
    .text-para {
      color: var(--primary-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin: 0 0 42px;
      max-width: 336px;
    }
    .customized-button {
      width: 100%;
      button {
        width: 100%;
      }
    }
    .form-control {
      min-width: auto;
    }
    .checkbox-wrap {
      p {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 10px auto 0;
        max-width: 340px;
        text-align: center;
      }
    }
    a.text-grey.cursor-pointer {
      font-size: 18px;
    }
  }
}
