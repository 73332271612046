@import "../../../../styles/colors.scss";
@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/typographies.scss";

@import "../../../../styles/animations.scss";
.chat-user-preview {
  border-radius: 10px;
  box-shadow: var(--card-box-shadow);
  background-color: #fff;
  display: flex;
  align-content: flex-start;
  text-align: left;
  padding: 20px 16px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  min-height: 100px;
  text-decoration: none;
  margin: 0 0 10px 0;
  &:hover {
    box-shadow: 0 1px 5px 3px var(--gray-outline-color) !important;
  }
  &:focus {
    box-shadow: 0 1px 5px 3px var(--gray-outline-color) !important;
  }
  + div {
    margin-top: 12px;
  }
  @media (max-width: 1366px) {
    padding: 16px;
  }
  &:last-child {
    border: none;
  }
  .chat-user-preview__profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    clip-path: circle();
  }
  .chat-user-preview__details {
    padding-left: 10px;
    margin-top: 0;
    flex-grow: 1;
    max-width: calc(100% - 50px);
    color: var(--primary-dark-color);
    .title {
      margin-top: 0;
      margin-bottom: 0;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      font-size: 18px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      @media (max-width: 1366px) {
        font-size: 16px;
      }
      > span {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h5 {
        margin: 0 5px 0 5px;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        font-family: "TTCommons-Regular";
        color: var(--field-label-color);
        font-weight: 400;
      }
      h6 {
        margin-top: 0;
        margin-bottom: 0;
        span {
          background-color: var(--secondary-color) !important;
          color: #fff;
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          font-weight: 400;
          transition: ease-in-out;
          padding-top: 3px;
          margin-left: 4px;
          margin-top: -4px;
        }
      }
    }
    &__tags {
      display: flex;
      gap: 8px;
      .badge {
        background: #ffffff !important;
        width: fit-content;
        height: fit-content;
        border-radius: 12.5px;
        &__content {
          background: rgba(247, 100, 95, 0.17) !important;
          color: #a80c06;
          height: 25px;
          border-radius: 12.5px;
          font-family: "TTCommons-Medium";
          font-size: 15px;
          line-height: 29px;
          font-weight: 500;
          text-align: center;
          letter-spacing: 0;
          padding-inline: 7.5px;
          display: block;
        }
      }
    }
    .description {
      font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
      font-weight: 400;
      font-size: 15px;
      max-height: 32px;
      overflow: hidden;
      font-style: normal;
      margin: 0;
      margin-top: 4px;
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.chat-user-preview--active {
  background-color: var(--primary-main-color) !important;
  .chat-user-preview__details {
    color: #fff;
    .badge {
      color: var(--primary-main-color);
    }
  }
}
