.sign-out-popup {
  .modal-title {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    + svg {
      top: -5px;
      right: -5px;
    }
  }
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 480px;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 30px;
      .MuiTypography-root {
        padding: 0 !important;
      }
    }
  }
}
