.topic-menu-dialog {
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 480px;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
  }
  .MuiDialogContent-root {
    width: 100% !important;
    padding: 30px;
    .MuiDialogContentText-root {
      padding: 0 !important;
      margin: 0 !important;
    }
    .makeStyles-dialogTitle-8 {
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
      color: var(--primary-color);
      max-width: 354px;
      button {
        padding: 20.65px 20px 14.85px;
        height: auto;
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
      }
    }
    .makeStyles-dialogSubTitle-9 {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      max-width: 336px;
      margin: 20px auto 0;
    }
    .makeStyles-btnClose-11,
    .makeStyles-btnClose-126 {
      top: -5px;
      right: -5px;
    }
  }
  .menu-dialog-content {
    margin-top: 24px;
    .content-box {
      .MuiInputBase-multiline {
        padding: 0;
        margin: 0;
      }
      .textbox-label {
        margin: 0 0 8px 0 !important;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
      }
      textarea {
        border: 2px solid var(--gray-outline-color);
        border-radius: 10px;
        background-color: #ffffff;
        padding: 20px 14px;
        max-width: 439px;
        margin: 0 auto;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        box-sizing: border-box;
        min-height: 100px;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--field-label-color) !important;
          opacity: 1 !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--field-label-color) !important;
          opacity: 1 !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--field-label-color) !important;
          opacity: 1 !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--field-label-color) !important;
          opacity: 1 !important;
        }
      }
      textarea:focus {
        &::-webkit-input-placeholder {
          color: transparent !important;
          opacity: 0 !important;
        }
        &::-moz-placeholder {
          color: transparent !important;
          opacity: 0 !important;
        }
        &:-ms-input-placeholder {
          color: transparent !important;
          opacity: 0 !important;
        }
        &:-moz-placeholder {
          color: transparent !important;
          opacity: 0 !important;
        }
      }
      fieldset {
        display: none;
      }
    }
    .btn {
      text-align: center;
      margin: 0 0 24px 0;
    }
  }
  &.report-feedback {
    .menu-dialog-content {
      .btn {
        margin-bottom: 0;
        button {
          padding: 4px 0 0 0;
          max-width: 125px;
          height: 30px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }
}
