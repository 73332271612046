@import "../../styles/colors.scss";
@import "../../styles/typographies.scss";

.profile-module-collapse-form-container {
  width: 100%;
  .profile-module-collapse-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    button {
      cursor: pointer;
      width: 108px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: var(--card-box-shadow) !important;
      border-radius: 100px;
      span {
        color: var(--primary-dark-color);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Medium";
        transition: all 0.3s;
        height: 15px;
      }
    }
    &__student {
      &--active {
        button {
          background: var(--primary-color);
          span {
            color: white;
          }
        }
      }
    }
    &__ambassador {
      button {
        width: 136px !important;
        span {
          margin-left: 5px;
        }
      }
      &--active {
        button {
          background: var(--primary-color);
          span {
            color: white;
          }
          img {
            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(348deg)
              brightness(108%) contrast(101%);
          }
        }
      }
    }
  }
  .profile-module-collapse-form-progress {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    &__container {
      width: 390px;
      max-width: 390px;
      position: relative;
      > div > div {
        height: 25px;
        display: flex;
        align-items: center;
        font-size: 16px !important;
        padding-top: 3px;
      }
    }
  }
}

.section__top-heading {
  color: var(--primary-dark-color);
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin: 12px 0 12px 0;
  padding-top: 4px;
  display: flex;
  span,
  a {
    margin-left: 8px;
  }
}
.divider {
  margin: 30px 0 40px 0;
  background: $color-grey;
  height: 2px;
  border: none;
}

.loading-container {
  max-width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  padding: 30px 60px;
  margin-bottom: 20px;
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > div {
      flex-grow: 1;
    }
    margin-bottom: 15px;
  }
}

.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: $color-grey;
  overflow: hidden;

  &::after {
    content: "";
    box-sizing: border-box;
    width: 0;
    height: 4.8px;
    background: $color-grey-4;
    position: absolute;
    top: 0;
    left: 0;
    animation: animFw 10s linear infinite;
  }

  @keyframes animFw {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}
