@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/colors.scss";
@import "../../../../styles/typographies.scss";
.chat-users {
  box-shadow: var(--card-box-shadow);
  padding: 15px;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    .chat-users__container {
      .user-more-info {
        &__actions {
          &__button {
            opacity: 1 !important;
            visibility: visible !important;
          }
          &__star {
            opacity: 1 !important;
            visibility: visible !important;
          }
        }
      }
    }
  }
  + div {
    margin-top: 15px;
  }
  .chat-users__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 600px) {
      align-items: flex-start;
      margin: 0;
      width: 100%;
    }
    .user-more-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      flex-wrap: wrap;
      gap: 10px;
      @include xss() {
        flex-direction: column;
        align-items: start;
      }
      .user-bio {
        display: flex;
        flex-direction: column;
        .user-bio_info {
          margin: 0;
          padding: 0;
          display: flex;
          @media (max-width: 600px) {
            flex-wrap: wrap;
          }
          .title {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            word-break: break-word;
            padding-top: 5px;
          }
          .badge {
            margin-left: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
            @media (max-width: 600px) {
              margin-left: 0;
              margin-bottom: 4px;
            }
            span {
              border-radius: 12.5px;
              background-color: #f1f4f7;
              color: #000c3d;
              font-family: "TTCommons-Regular";
              font-size: 15px;
              line-height: 21px;
              text-align: center;
              padding: 4px 8px 0;
              min-width: 69px;
              display: inline-block;
            }
            &--user {
              background-color: #d7ddf0 !important;
              color: var(--primary-dark-color) !important;
            }
            &--red {
              background-color: rgba(247, 100, 95, 0.17) !important;
              color: #a80c06 !important;
            }
          }
        }
        .user-bio__profesion {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 15px;
          display: block;
          width: fit-content;
          word-break: break-word;
          padding-top: 5px;
          margin: 0 0 6px 0;
        }
        .user-location {
          color: var(--primary-dark-color);
          font-size: 15px;
          line-height: 15px;
          display: flex;
          align-items: center;
          font-family: "TTCommons-Regular";
          span:first-child {
            height: 15px;
            width: 22px;
            border-radius: 2px;
            background-color: #d8d8d8;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            overflow: hidden;
            margin: -4px 0 0 0;
            box-shadow: 0 1px 4px 0 rgba(209, 221, 229, 0.4);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
            + span {
              margin-left: 8px;
            }
          }
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        &__button {
          opacity: 0;
          visibility: hidden;
          @media (max-width: 1023px) {
            opacity: 1;
            visibility: visible;
          }
        }

        &__star {
          margin-left: 12px;
          opacity: 0;
          visibility: hidden;
          @media (max-width: 1023px) {
            opacity: 1;
            visibility: visible;
          }
          &--active {
            opacity: 1;
            visibility: visible;
          }
        }
        &__showActions {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
