.boost-complete-dialog {
  .MuiDialogContent-root {
    padding: 0;
    .MuiDialogContentText-root {
      padding: 50px !important;
      margin: 0;
      .makeStyles-dialogHeader-3 {
        position: relative;
      }
      .progressbar-wrapper {
        width: 100%;
        max-width: 440px;
        margin: 20px 0 30px;
      }
      .q-info-div {
        text-align: center;
        p {
          color: var(--primary-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          max-width: 336px;
          margin: 0 auto 60px;
          font-weight: 500;
        }
        button {
          border-radius: 45px;
          max-width: 248px;
          padding: 19.85px 15px 15.85px;
          height: auto;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24.3px;
          text-align: center;
          margin: 0 0 30px 0;
        }
        .later-link {
          span {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center;
            font-family: "TTCommons-Regular";
            border-bottom: 1px solid var(--gray-text-color);
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }
  }
}
