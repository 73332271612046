.responsive-popup {
  .MuiPaper-root {
    max-width: 480px;
    padding: 24px;
    min-width: auto;
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    max-height: calc(100% - 40px);
    margin: 20px auto;
    overflow: auto;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      max-width: 100%;
      margin: 0 auto;
      overflow: initial !important;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin: 0 0 10px;
    }
    .text-para {
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin: 0 0 25px;
    }
    .got-it-button {
      button {
        width: 114px;
        height: 30px;
      }
    }
  }
  .model-body {
    > div {
      width: 100%;
    }
  }
  .MuiDialogContent-root {
    overflow-x: hidden !important;
  }
}
.edit-saved-search-popup {
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 480px;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 30px;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0;
      }
    }
  }

  .modal-title {
    margin: 0 0 16px 0;
  }
  .edit-saved-search {
    width: 100%;
    max-width: 320px;
    .form-field-group {
      .textbox-label {
        justify-content: center;
        margin-left: 0;
      }
      .custom-textbox {
        input {
          text-align: center;
        }
      }
      .react-custom-dropdown {
        > div {
          text-align: center;
          > div {
            > div {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
    .search-card {
      box-shadow: none;
      .MuiCardContent-root {
        padding: 0;
        label.form-label {
          margin: 0 0 4px 0 !important;
        }
        .searchBtn {
          button {
            width: 100%;
            margin: 16px 0 0 0;
          }
        }
      }
    }
  }
}
.mobile-profile-popup {
  .modal-title {
    display: none;
  }
  .model-body {
    > div {
      width: 100%;
    }
    .card-wrapper {
      .recom-details-card {
        box-shadow: none;
        padding: 0 !important;
        max-width: 320px;
        margin: 0 auto;
        overflow: visible;
      }
    }
  }
  .right-section {
    .details {
      padding: 0 !important;
      box-shadow: none;
      max-width: 320px;
      margin: 0 auto;
      overflow: visible;
      .decription {
        max-width: 100%;
      }
      .card-close-btn {
        top: -5px;
        right: -5px;
      }
    }
  }
}

.mobile-calendar-event-popup {
  .modal-title {
    display: none;
  }
  .model-body {
    > div {
      width: 100%;
      max-width: 100%;
      margin: 0 auto !important;
      .MuiPaper-root {
        box-shadow: none;
        .card-content {
          padding: 0;
        }
      }
    }
  }
}
