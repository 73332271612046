@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";
.o-career-container{
  display: flex;
  gap: 30px;
  row-gap: 15px;
  flex-wrap: wrap;
  align-items: flex-end;
  &__item{
    width: 265px;
  }
}
