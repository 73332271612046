.profile-pic-popup {
  &.accessibility {
    h2.modal-title {
      margin-bottom: 10px !important;
    }
  }
  .MuiPaper-root {
    height: auto;
    width: 670px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    padding: 50px;
    overflow: visible;
    box-sizing: border-box;
    overflow-y: auto !important;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      overflow: visible;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin: 0 0 39px;
      + svg {
        top: -5px;
        right: -5px;
      }
    }
    .model-body {
      .profile-upload {
        text-align: center;
        margin: 0 0 60px;
        &.accessibility {
          margin: 15px 0 30px;
          .profile-img-wrap {
            margin: 0;
          }
        }
        .profile-img-wrap {
          width: 112px;
          height: 112px;
          border-radius: 100px;
          margin: 0 auto 9px;
          overflow: hidden;
          .profile-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .profile-upload-link {
          img {
            height: 16px;
            width: 16px;
            margin-right: 10px;
            margin-bottom: 0px;
          }
          .btn-upload {
            color: var(--secondary-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            border-bottom: 1px solid var(--secondary-color);
          }
        }
      }
      .customized-button {
        margin: 0 0 30px;
        button {
          height: auto;
          width: auto;
          padding: 20.85px 25px 14.85px 25px;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24.3px;
          min-width: 248px;
          text-align: center;
          border-radius: 45px;
        }
      }
      a.text-grey.cursor-pointer {
        color: var(--gray-text-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        border-bottom: 1px solid var(--gray-text-color);
        text-decoration: none;
      }
    }
    .text-para {
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin: 0 0 15px;
    }
    .got-it-button {
      button {
        width: 114px;
        height: 30px;
      }
    }
  }
}
