@import "../../../../src/styles/colors.scss";
@import "../../../../src/styles/typographies.scss";
@import "../../../../src/styles/custom.scss";
.o-career-container {
  &__top-heading {
    margin: 0;
    color: var(--primary-dark-color);
    @include TTCommonsMedium;
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 15px;
    padding-top: 4px;
  }
}
.o-career-information {
  margin-top: 30px;
  &__top-heading {
    margin: 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 16px;
    padding-top: 4px;
  }
}
.o-career-visa-status {
  margin-top: 30px;
  &__top-heading {
    margin: 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 16px;
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    span {
      cursor: pointer;
    }
  }
}
.o-career-container__next-button {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 15px;
  margin-bottom: 80px;
}
