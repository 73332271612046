@import "../../../../src/styles/colors.scss";
@import "../../../../src/styles/typographies.scss";
@import "../../../../src/styles/custom.scss";
@import "../../../../src/styles/breakpoints.scss";
.profile-info {
  background: var(--primary-main-color);
  border-radius: 10px;
  max-width: 900px;
  margin: 50px auto 0;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 46px 40px 52px;
    gap: 40px;
    @include tablet {
      flex-direction: column;
      padding: 78px 20px;
    }

    &--img-left {
      max-width: 230px;
      img {
        width: 100%;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;

      @include tablet {
        text-align: center;
        button {
          margin: 0 auto;
        }
      }

      h1 {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        @include TTCommonsDemiBold;
        margin-bottom: 4px !important;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        @include TTCommonsRegular;
        margin-bottom: 20px !important;
      }
      h1,
      p {
        color: $color-white;
        margin: 0;
      }
    }
  }
}
