@import "../../../src/styles/colors.scss";
@import "../../../src/styles/typographies.scss";
@import "../../../src/styles/custom.scss";

.a-info-icon-tooltip {
  margin-left: 8px;
  display: flex;
  margin-top: -4px;
  text-decoration: none;
}
