.custom-upload{
  &__controls{
    margin-left: 12px;
    &__buttons {
      display: flex;
      flex-wrap: wrap;
    }
    a {
      cursor: pointer;
      color: var(--link-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      border-bottom: 1px solid var(--link-color);
      text-decoration: none;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
