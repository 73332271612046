@import "../../../../styles/colors.scss";
@import "../../../../styles/typographies.scss";
@import "../../../../styles/animations.scss";
@import "../../../../styles/breakpoints.scss";

.group-profile__container {
  position: relative;
  box-shadow: var(--card-box-shadow);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  position: relative;
  width: 100%;
  overflow-y: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar {
    width: 4px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: $color-blue-2 !important; /* color of the tracking area */
    border-radius: 60px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-4 !important; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none;
    height: 70px !important;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0;
  }
  &__actions {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .profile-shimmer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      width: 200px;
      height: 20px;
    }
    &__text {
      width: 200px;
      height: 8px;
    }
    &__avatar {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }
    &__line {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      height: 25px;
      border-radius: 4px;
    }
  }

  .group-profile__title {
    margin-top: 10px;
    > span {
      color: var(--primary-dark-color);
      font-size: 18px;
      line-height: 19px;
      margin: 0;
      @include TTCommonsMedium;
      display: block;
      font-weight: 500;
    }
  }
  .group-description {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin: 0px auto 15px auto;
    margin: 8px 0 0 0;
  }
  .group-participants--count {
    margin: 15px 0 8px 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    padding: 0;
  }
  .participants {
    height: fit-content;
    overflow-y: auto;
    padding: 0 16px 0 16px;
    margin: 0;
    @media (max-width: 1024px) {
      height: 320px;
    }

    @include xss() {
      width: 90% !important;
    }

    scrollbar-width: thin;
    -ms-overflow-style: auto;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: $color-grey-4 transparent; /* scroll thumb and track */
    transition: scrollbar-color 0.3s ease-out;
    h4,
    h5 {
      margin: 0;
    }
  }
}

.participants::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}

.participants::-webkit-scrollbar-track {
  background: $color-blue-2 !important; /* color of the tracking area */
  border-radius: 60px;
}

.participants::-webkit-scrollbar-thumb {
  background-color: $color-grey-4 !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 70px !important;
}

.participants::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.participants::-webkit-scrollbar-thumb:hover {
  border: 0;
}
