.signup-accessibility-popup {
  .MuiPaper-root {
    max-width: 441px;
    padding: 30px;
    min-width: 441px;
    box-sizing: border-box;
    .MuiDialogContent-root {
      width: 100% !important;
      padding: 0;
      overflow: visible;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      margin: 0 0 14px;
    }
    .text-para {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      letter-spacing: 0;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      margin: 0 0 30px;
    }
    .got-it-button {
      button {
        width: 238px;
        height: 50px;
        border-radius: 100px;
        font-size: 18px;
        font-weight: 500;
        line-height: 17px;
      }
    }
  }
}
