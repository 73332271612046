@import "../../../../../styles/colors.scss";

.m-alum-deg {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    @media (max-width: 1081px) {
      padding: 0 !important;
      border-bottom: 0 !important;
    }
    &__title {
      font-family: "TTCommons-Medium";
      color: $primary-font-color;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
      margin: 0 0 20px 0;
      @media (max-width: 1367px) {
        font-size: 22px;
        line-height: 22px;
        width: 100%;
      }
      @media (max-width: 1081px) {
        padding: 14px 8px 12px 16px !important;
      }
      @media (max-width: 767px) {
        font-size: 16px !important;
        line-height: 16px !important;
      }
    }
    a {
      @media (max-width: 1081px) {
        position: absolute;
        right: 8px;
        z-index: 1;
        top: 10px;
      }
    }
    &__filters {
      display: flex;
      gap: 10px;
      @media (max-width: 1081px) {
        padding: 16px 16px 8px 16px;
        border-top: 1px solid #d1dde5;
        margin-top: 0 !important;
      }
      > div:nth-child(2) > div > div > div[class$="-control"] > div {
        padding: 0;
      }
      > div {
        width: 202px;
      }
      > div:nth-child(4) {
        width: 123px; // Specific rule for the fourth child "year"
      }
      @media (max-width: 1400px) {
        margin-top: 10px;
        width: 100%;
        justify-content: flex-end;
        flex-wrap: wrap;
      }
      > div {
        @media (max-width: 1080px) {
          float: left;
          margin: 0px 8px 8px 0;
        }
        @media (max-width: 767px) {
          float: none;
          margin: 0px 0px 16px 0;
          width: 100% !important;
        }
      }
    }
  }
  &__body {
    margin-top: 20px;
    width: 100%;
    &__card {
      > div {
        padding: 16px !important;
      }
      &__loader {
        height: 456px;
        > div {
          > div {
            width: 100% !important;
            height: 456px !important;
            margin-bottom: 0px !important;
          }
        }
      }
      &__no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        img {
          width: 100%;
        }
        h3 {
          margin: 20px 0 0 0;
          font-family: "TTCommons-Medium";
          color: $primary-font-color;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
      &__graph {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 456px;
      }
    }
  }
}
