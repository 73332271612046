@import "../../../../styles/colors.scss";
@import "../../../../styles/breakpoints.scss";
.contacts-list {
  -ms-overflow-style: auto;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $color-grey transparent; /* scroll thumb and track */
  transition: all ease-in-out 1000ms;
  overflow-y: auto;
  overflow: auto;
  max-height: 665px;
  padding: 10px;
  margin-top: -10px;
  @media (max-width: 767px) {
    max-height: 328px;
  }
  &__filters {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    &__item {
      height: 35px;
      border-radius: 40px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        height: 15px;
      }
      &--active {
        background-color: #000485 !important;
        span {
          color: #ffffff;
        }
      }
    }
    > div:nth-child(1) {
      width: 54px;
    }
    > div:nth-child(2) {
      width: 95px;
    }
    > div:nth-child(3) {
      width: 121px;
    }
  }
}

.contacts-list::-webkit-scrollbar {
  width: 8px;
}
.contacts-list::-webkit-scrollbar-track {
  margin-top: 10px;
  background: #ffffff !important; /* color of the tracking area */
  border: 2px solid $color-grey-4;
  border-radius: 50px;
}

.contacts-list::-webkit-scrollbar-thumb {
  background-color: $color-grey-4 !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 10px;
}

.contacts-list::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.contacts-list::-webkit-scrollbar-thumb:hover {
  border: 0;
}
