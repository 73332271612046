.resources {
  position: relative;
  margin-bottom: 75px;
  &__header {
    margin: 42px 187px 30px 187px;
    @media (max-width: 890px) {
      margin: 42px 0 20px 0;
    }
    h2 {
      margin: 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    @media (max-width: 555px) {
      margin-bottom: 50px;
    }
    &__input {
      margin-right: 10px;
      height: 50px;
      width: 285px;
    }
    &__controls {
      height: 50px;
      position: relative;
      img {
        cursor: pointer;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        img {
          vertical-align: middle;
        }
        &:focus {
          box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
        }
      }
      &__clear {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 15px;
        left: 63px;
        border-radius: 0 !important;
        letter-spacing: 0;
        font-size: 18px;
        line-height: 16px;
        color: var(--gray-text-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--gray-text-color);
        cursor: pointer;
        padding-top: 3px;
        white-space: nowrap;
        transition: all 0.3s;
        @media (max-width: 555px) {
          top: 65px;
          left: -155px;
        }
        &:focus {
          color: var(--link-color) !important;
          border-color: var(--link-color) !important;
          opacity: 0.9 !important;
          outline: 2px solid var(--primary-dark-color) !important;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &__body {
    &__categories {
      display: flex;
      justify-content: center;
      &__loader {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
      }
      &__placeholder {
        display: flex;
        justify-content: center;
        flex-direction: column;
        h3 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 30px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 31px;
          text-align: center;
          margin: 0px 0px 10px 0px;
        }
      }
      &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -10px;
        width: 100%;
        @media (max-width: 600px) {
          margin: 0 -8px;
        }
        &__card {
          padding: 0 10px;
          width: 20%;
          margin: 0 0 20px 0;
          @media (max-width: 1366px) {
            width: 25%;
          }
          @media (max-width: 991px) {
            width: 33.33%;
          }
          @media (max-width: 600px) {
            width: 50%;
            padding: 0 8px;
          }
          > div > div {
            &:hover {
              box-shadow: 0 1px 14px 3px var(--gray-outline-color);
            }
          }
          > div > div > div {
            padding: 20px !important;
            width: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 600px) {
              padding: 15px !important;
            }
          }
          &__item {
            width: 100%;
            &__image {
              width: 100%;
              height: 140px;
              margin-bottom: 16px;
              @media (max-width: 600px) {
                height: 100px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            span {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 22px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 24px;
              text-align: center;
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              display: block;
            }
          }
        }
      }
    }
    &__saved {
      &__title {
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        margin: 20px auto 0;
        justify-content: center;
        h2 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          margin: 0;
        }
        &__view-all {
          margin-left: 14px;
          span {
            cursor: pointer;
            color: var(--link-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            border-bottom: 1px solid var(--link-color);
          }
        }
      }
      &__body {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 30px -10px 0;
        > div {
          padding: 0 10px;
          margin: 0 0 20px 0;
          width: 25%;
          @media (max-width: 1366px) {
            width: 33.33%;
          }
          @media (max-width: 767px) {
            width: 50%;
            padding: 0 8px;
          }
          @media (max-width: 480px) {
            width: 100%;
          }
        }
      }
    }
  }
  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: -14px;
    @media (max-width: 890px) {
      position: initial;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
    }
    &__back-to-categories {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 0 15px 20px;
      width: 187px;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      text-decoration: none;
      transition: all 0.3s;
      span {
        margin-left: 3.5px;
        height: 16.5px;
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
      img {
        width: 13px;
      }
      &--saved {
        width: 92px;
      }
      &:hover,
      &:focus {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
    }
    &__to-saved-service {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 20px;
      width: 285px;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      span {
        margin-inline: 5px;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        height: 17px;
      }
    }
  }
  &__filters {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    flex-direction: column;
    @media (max-width: 767px) {
      margin: 20px 0 55px 0;
    }
    &__search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      &__input {
        margin-right: 10px;
        height: 50px;
        width: 285px;
      }
      &__controls {
        height: 50px;
        position: relative;
        img {
          cursor: pointer;
        }
        &__clear {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 15px;
          left: 63px;
          @media (max-width: 555px) {
            top: 65px;
            left: -155px;
          }
          a {
            letter-spacing: 0;
            font-size: 18px;
            line-height: 16px;
            color: var(--gray-text-color);
            font-family: "TTCommons-Medium";
            font-weight: 500;
            text-decoration: none;
            border-bottom: 2px solid var(--gray-text-color);
            cursor: pointer;
            padding-top: 3px;
            white-space: nowrap;
            border-radius: 0 !important;
            transition: all 0.3s;
            &:focus {
              color: var(--link-color) !important;
              border-color: var(--link-color) !important;
              opacity: 0.9 !important;
              outline: 2px solid var(--primary-dark-color) !important;
            }
            &:hover {
              opacity: 0.8;
            }
          }
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          img {
            vertical-align: middle;
          }
          &:focus {
            box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
          }
        }
      }
    }
    &__type {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      @media (max-width: 767px) {
        margin-top: 20px;
      }
      &__items {
        max-width: 565px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
        position: relative;
        &__heading {
          position: absolute;
          top: 8px;
          left: -58px;
          @media (max-width: 767px) {
            left: initial;
            top: -28px;
          }
          span {
            color: var(--field-label-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
          }
        }
        &__item {
          height: 35px;
          width: 129px;
          border-radius: 40px;
          background-color: #ffffff;
          box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          span {
            height: 14px;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            text-transform: capitalize;
          }
          &--active {
            background-color: var(--primary-main-color);
            span {
              color: #ffffff;
            }
          }
        }
        &__clear-all {
          position: absolute;
          @media (max-width: 319px) {
            top: 203px;
          }
          @media (min-width: 320px) {
            top: 203px;
          }
          @media (min-width: 449px) {
            top: 150px;
          }
          @media (min-width: 594px) {
            top: 100px;
          }
          @media (min-width: 767px) {
            top: 9px;
            right: -76px;
          }
          span {
            letter-spacing: 0;
            font-size: 18px;
            line-height: 16px;
            color: var(--gray-text-color);
            font-family: "TTCommons-Medium";
            font-weight: 500;
            text-decoration: none;
            border-bottom: 2px solid var(--gray-text-color);
            cursor: pointer;
            padding-top: 3px;
            transition: all 0.3s;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    &__sort {
      margin-top: 16px;
      @media (max-width: 767px) {
        margin-top: 45px;
      }
      @media (min-width: 1366px) {
        margin-top: 0px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
      > div > div {
        margin-bottom: 0px !important;
      }
      > div > div > div > div {
        width: 160px !important;
        min-height: 35px !important;
        box-shadow: var(--card-box-shadow);
        border: none !important;
      }
    }
  }
  &__listing {
    &__loaders {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 40px;
    }
    &__cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 40px -10px 75px;
      > div {
        padding: 0 10px;
        margin: 0 0 20px 0;
        width: 25%;
        @media (max-width: 1366px) {
          width: 33.33%;
        }
        @media (max-width: 767px) {
          width: 50%;
          padding: 0 8px;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }
  &__no-saved-resources {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 256px;
    }
    &__heading {
      margin: 19px 0 9px 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      @media (min-width: 400px) {
        width: 325px;
      }
    }
  }
  a {
    text-decoration: none;
  }
}

.resource_card {
  position: relative;
  > div > div > div > div {
    padding: 20px;
    width: 100%;
    padding-bottom: 20px !important;
  }
  > div > div > div {
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
  }
  &:hover {
    .resource_card__star {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
  &__wrapper {
    cursor: pointer;
    &__content {
      position: relative;
      height: 315px;
      &__image {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 145px;
        margin-bottom: 10px;
        background: #ebf1f7;
        border-radius: 10px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &__secondary {
          position: absolute;
          width: 215px;
          height: 125px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            opacity: 0.7;
            width: 72px;
            height: 72px;
          }
        }
      }
      &__title {
        margin-bottom: 8px;
        span {
          display: block;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          max-height: 57px;
          overflow: hidden;
        }
      }
      &__description {
        min-height: 52px;
        margin-bottom: 8px;
        span {
          display: block;
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          max-height: 52px;
          overflow: hidden;
        }
      }
      &__school {
        position: absolute;
        left: 0;
        bottom: 16px;
        height: 20px;
        &__super-admin {
          display: flex;
          align-items: center;
          height: 20px;
          width: fit-content;
          span {
            height: 20px;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
          }
          img {
            height: 20px;
            margin-right: 6px;
          }
        }
        &__school-admin {
          width: fit-content;
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 23px;
          text-align: center;
          padding-inline: 10px;
          height: 20px;
          border-radius: 12.5px;
          background-color: #0030b5;
        }
      }
      &__footer {
        position: absolute;
        bottom: -8px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        &__date {
          span {
            display: block;
            height: 14px;
            color: var(--field-label-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
        &__type {
          background-color: var(--icon_background);
          color: var(--primary-dark-color);
          min-width: 88px;
          height: 20px;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          border-radius: 10px;
          padding: 4px 10px 4px 10px;
        }
      }
    }
  }
  &__star {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 30px;
    right: 38px;
    &--active {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
  &__label {
    position: absolute;
    top: 31px;
    left: 30px;
    height: 20px;
    background: #ffffff;
    box-shadow: var(--card-box-shadow);
    padding: 3px 10px 2px 10px;
    border-radius: 10px;
    span {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      display: block;
      max-width: 135px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__showActions {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
