.save-search-modal {
  .MuiDialog-paperScrollPaper {
    overflow: visible;
  }
  .MuiDialogContent-root {
    padding: 30px;
    max-width: 480px;
    overflow: visible !important;
    .MuiDialogContentText-root {
      padding: 0 !important;
      margin: 0;
    }
    form {
      .model-body {
        > div {
          width: 100%;
          max-width: 285px;
          text-align: center;
          .textbox-label {
            justify-content: center !important;
          }
          input {
            text-align: center;
          }
          .css-uk6wr9-ValueContainer {
            justify-content: center;
          }
        }
        .btn {
          margin: 15px 0 0 0;
        }
      }
    }
  }
  &.subscribe-modal {
    .MuiDialogContent-root {
      padding: 30px;
      svg.makeStyles-btnClose-11 {
        right: 15px;
      }
    }
    .makeStyles-dialogSubTitle-9 {
      max-width: 500px;
      width: 100%;
    }
    .btn {
      button {
        max-width: 100% !important;
      }
    }
  }
}
