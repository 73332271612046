.suggested-topics {
  margin-bottom: 15px;
  &__title {
    margin: 25px 0 20px 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__item {
      cursor: pointer;
      transition: all 0.3s;
      > div {
        > div {
          > div {
            background-color: rgba(255, 255, 255, 0.7) !important;
          }
        }
      }
      &__body {
        &__title {
          display: flex;
          margin-bottom: 6px;
          &__avatar {
            margin-right: 10px;
          }
          &__text {
            &__line-one {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
            }
            &__line-two {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 19px;
            }
          }
        }
        &__labels {
          display: flex;
          align-items: center;
          gap: 10px;
          &__type {
            display: inline-block;
            height: 18px;
            color: #0231b5;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 19px;
            margin-top: 3px;
          }
          &__tag {
            min-width: fit-content;
            padding: 6px 10px;
            height: 25px !important;
            margin: 0 0 0 0;
            font-family: "TTCommons-Regular";
            text-align: center;
            color: #ffffff;
            background: var(--link-color);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 16px;
            border-radius: 12.5px;
            &--ambassador {
              color: #a80c06;
              background: rgba(247, 100, 95, 0.09);
            }
          }
        }
        &__description {
          margin-top: 6px;
          p {
            margin: 0 0 0 0;
            color: var(--gray-text-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 19px;
          }
        }
        &__actions {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          &__view-topic {
            button {
              background: #ffffff !important;
              box-shadow: var(--card-box-shadow) !important;
              &:hover {
                box-shadow: var(--card-box-shadow-hover) !important;
              }
            }
            &__body {
              display: flex;
              align-items: center;
              justify-content: space-around;
              span {
                color: var(--primary-dark-color);
                font-family: "TTCommons-Medium";
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                text-transform: initial !important;
              }
              img {
                margin-bottom: 3px;
              }
            }
          }
          &__request {
            button {
              background: var(--link-color) !important;
              box-shadow: var(--card-box-shadow) !important;
              &:hover {
                box-shadow: var(--card-box-shadow-hover) !important;
              }
            }
          }
        }
      }
      &--selected {
        > div {
          > div {
            > div {
              background-color: #ffffff !important;
              box-shadow: var(--card-box-shadow-hover) !important;
            }
          }
        }
      }
    }
  }
}
