.resource-dialog {
  .MuiDialogContent-root {
    padding: 50px !important;
    box-sizing: border-box;
    .MuiTypography-root {
      padding: 0 !important;
      margin: 0;
    }
    .modal-title {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
      padding-top: 2px;
    }
  }
  .model-body {
    margin-top: 20px;
    .PDF-Wrap {
      iframe {
        border: 0;
        width: 100%;
        min-height: 400px;
        vertical-align: top;
      }
    }
    .player-wrapper-yt {
      position: relative;
      padding-top: 56.25%; /* 720 / 1280 = 0.5625 aspect ratio*/
    }
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
    .interstride-video-Wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      video {
        width: 100%;
        max-height: 385px;
      }
    }

    h3 {
      color: var(--primary-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
    }
  }
}
.resource-dialog {
  .MuiDialogContent-root,
  .MuiPaper-root {
    overflow: hidden;
  }
  .model-body {
    max-width: 100%;
  }
}
