@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";

.o-professional-language{
  &__grid{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 15px;
    &__item{
      max-width: 265px;
      width: 265px;
    }
  }
}
